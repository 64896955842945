import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import {
  API_KEY,
  API_POSTS,
  API_POSTS_DELETE,
  API_CLIENTS_NAME,
  API_POSTS_MONTH,
  API_POSTS_EDIT,
  API_GENERATE_AI_POST,
  API_GET_CONTENT_STRATEGIES,
  API_GET_PROMPTS,
  MONTHS_TO_GENERATE
} from '../../utils';

import ViewPostsInCalendar from '../../components/ViewPostsInCalendar';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeline from '@fullcalendar/timeline';
import list from '@fullcalendar/list';
import Swal from 'sweetalert2';
import bootstrap from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import Clock from 'react-live-clock';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { postsSelector, setSelectedPost } from '../../store/slices/posts';
import { clientsSelector, getClients } from '../../store/slices/clients';
import {
  setSelectedClient,
  selectedClientSelector
} from '../../store/slices/selectedClient';
import { SearchIcon, NotesIcon } from '../../Icons';
import UserGallery from './GallerySection/UserGallery';
import PostsSection from './PostsSection/PostsSection';
import NewPost from '../../components/NewPost';
import { authSelector } from '../../store/slices/auth';
import * as Theme from '../../theme/theme';
import './Calendar.scss';
import './Modal-calendar.css';
import NotesModal from '../../components/Notes/NotesModal.js';
import AccountStatus from '../../components/Notes/AccountStatus';
import AIToolsPost from '../../components/AIToolsPost';
import XLSX from 'xlsx';
import {
  resetContentState,
  generateContent as showLoadingModal
} from '../../store/slices/generalState';
import LoadingModal from './LoadingModal';
import LoadingSpinner from '../../pages/Calendar/GallerySection/StockImagesComponents/Partials/LoadingSpinner';

const moment = require('moment-timezone');
const axios = require('axios');

function CalendarScreen({ match }) {
  const history = useHistory();
  const [imagesLimit, setImagesLimit] = useState(3);
  const [refresh, setRefresh] = useState(true);
  const { customerList } = useSelector(clientsSelector);
  const { selectedClient } = useSelector(selectedClientSelector);
  const { selectedPost } = useSelector(postsSelector);
  const [loadingPosts, SetLoadingPosts] = useState(undefined);
  const [postsLists, SetPostsLists] = useState([]);
  const [newPost, setNewPost] = useState(undefined);
  const [listSelectedToDelete, setListSelectedToDelete] = useState([]);
  const [listSelectedtoSwitch, setListSelectedtoSwitch] = useState([]);
  const [queryDate, setQueryDate] = useState(
    `${new Date().getMonth() + 1} ${new Date().getFullYear()}`
  );
  const [checkDeleteMultiple, setCheckDeleteMultiple] = useState(false);
  const [checkSwitchMultiple, setCheckSwitchMultiple] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector(authSelector);
  const { shouldGenerateContent } = useSelector((state) => state.generalState);
  const [refresh2, setRefresh2] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [totalLoaded, setTotalLoaded] = useState(1);
  const [totalDays, setTotalDays] = useState(1);
  const [error, setError] = useState('');
  const [showPrompts, setShowPrompts] = useState(false);
  const [contentStrategy, setContentStrategy] = useState({});
  const [contentStrategyName, setContentStrategyName] = useState('');

  useEffect(() => {
    if (showPrompts) {
      axios
        .get(
          `${API_GET_CONTENT_STRATEGIES}?id=${selectedClient?.contentStrategy}`,
          {}
        )
        .then((res) => {
          setContentStrategy(res?.data?.contentStrategies);
        });
    }
  }, [showPrompts, selectedClient.userId, selectedClient?.contentStrategy]);

  useEffect(() => {
    axios
      .get(
        `${API_GET_CONTENT_STRATEGIES}?id=${selectedClient?.contentStrategy}`,
        {}
      )
      .then((res) => {
        setContentStrategyName(res?.data?.contentStrategies?.strategyName);
      });
  }, [selectedClient?.contentStrategy]);

  useEffect(() => {
    setShowPrompts(false);
  }, [selectedClient.id]);

  useEffect(() => {
    if (customerList.length === 0) {
      dispatch(getClients());
    }
  }, [customerList, dispatch]);
  useEffect(() => {
    if (customerList.length === 0) {
      dispatch(getClients());
    }
  }, []);

  const handleShowPrompts = () => {
    SetPostsLists([]);

    setShowPrompts(!showPrompts);
  };

  useEffect(() => {
    async function getURLClient() {
      const client = await axios.get(`${API_CLIENTS_NAME}${match.params.id}`, {
        headers: {
          'X-API-KEY': API_KEY,
          Authorization: `bearer ${userInfo.accessToken}`
        }
      });

      if (client.data) {
        dispatch(setSelectedClient(client.data));
      }
      return client;
    }

    getURLClient();
    // eslint-disable-next-line
  }, [match.params.id]);

  useEffect(() => {
    if (queryDate && selectedClient.currentClientId !== '') {
      fetchPosts(queryDate);
    }

    // eslint-disable-next-line
  }, [queryDate, selectedClient, showPrompts]);

  useEffect(() => {
    setNewPost();
  }, [newPost]);

  useEffect(() => {
    setRefresh2(!refresh2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsLists]);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      color: state.selectProps.menuColor,
      padding: 10,
      overflow: 'hidden'
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#c3c3c3',
      marginTop: '0.2em'
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '0.5em'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };
  const checkDeleteMultipleState = () => {
    setCheckDeleteMultiple(!checkDeleteMultiple);
    setListSelectedToDelete([]);
    if (checkDeleteMultiple === false) {
      setListSelectedToDelete([]);
    }
  };

  const checkSwitchMultipleState = () => {
    setCheckSwitchMultiple(!checkSwitchMultiple);
    setListSelectedtoSwitch([]);
    if (checkSwitchMultiple === false) {
      setListSelectedtoSwitch([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPosts = async (date) => {
    try {
      let arr = date.split(' ');
      const month = Number(arr[0]);
      const year = Number(arr[1]);
      let dateNow = {
        year: year.toString(),
        month: month.toString(),
        clientId: selectedClient.id
      };

      SetLoadingPosts(true);
      // HERE WE ASK FOR POSTS
      let res = showPrompts
        ? await axios.request({
            method: 'post',
            url: API_GET_PROMPTS,
            data: {
              csId: selectedClient?.clientGroups[0]?.id,
              clId: selectedClient.id,
              timeZone: selectedClient.timeZone,
              month: dateNow.month,
              year: dateNow.year
            }
          })
        : await axios.post(API_POSTS_MONTH, {
            ...dateNow,
            timeZone: selectedClient.timeZone
          });

      //
      SetPostsLists(showPrompts ? res.data.mergedArray : res.data.posts);
      SetLoadingPosts(false);
      return showPrompts ? res.data.mergedArray : res.data.posts;
    } catch (error) {
      console.error(error);
    }
  };
  const getTime = (currentClientTimeZone, datePost) => {
    let date = null;
    switch (currentClientTimeZone) {
      case 'Eastern Daylight Time - (GMT-4)':
        date = moment(datePost)
          .tz('America/New_York')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Central Daylight Time - (GMT-5)':
        date = moment(datePost)
          .tz('America/Chicago')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Mountain Daylight Time - (GMT-6)':
        date = moment(datePost).tz('America/Denver').format('YYYY-MM-DDTHH:mm');
        break;
      case 'Mountain Standard Time - (GMT-7)':
        date = moment(datePost)
          .tz('America/Phoenix')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Pacific Daylight Time - (GMT-7)':
        date = moment(datePost)
          .tz('America/Los_Angeles')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Alaska Daylight Time - (GMT-8)':
        date = moment(datePost)
          .tz('America/Anchorage')
          .format('YYYY-MM-DDTHH:mm');
        break;
      default:
        date = moment(datePost)
          .tz('Pacific/Honolulu')
          .format('YYYY-MM-DDTHH:mm');
        break;
    }
    return date;
  };

  const getTimeZoneClock = (currentClientTimeZone) => {
    let timeZoneClock = null;
    switch (currentClientTimeZone) {
      case 'Eastern Daylight Time - (GMT-4)':
        timeZoneClock = 'America/New_York';
        break;
      case 'Central Daylight Time - (GMT-5)':
        timeZoneClock = 'America/Chicago';
        break;
      case 'Mountain Daylight Time - (GMT-6)':
        timeZoneClock = 'America/Denver';
        break;
      case 'Mountain Standard Time - (GMT-7)':
        timeZoneClock = 'America/Phoenix';
        break;
      case 'Pacific Daylight Time - (GMT-7)':
        timeZoneClock = 'America/Los_Angeles';
        break;
      case 'Alaska Daylight Time - (GMT-8)':
        timeZoneClock = 'America/Anchorage';
        break;
      default:
        timeZoneClock = 'Pacific/Honolulu';
        break;
    }
    return timeZoneClock;
  };

  const imagesSection = () => {
    return (
      <PostsSection
        changeView={() => setImagesLimit(imagesLimit + 3)}
        limit={imagesLimit}
        postsListData={!showPrompts ? postsLists : []}
        client={selectedClient}
        setState={(data) => {
          setQueryDate(`${data.monthFilterPosts} ${data.yearFilterPosts}`);
        }}
        monthFilterPosts={queryDate.split(' ')[0]}
        yearFilterPosts={queryDate.split(' ')[1]}
      />
    );
  };

  const onSelectPost = (event, forced) => {
    if (event.view.type === 'listMonth' && !forced) return;
    let obj = event.event;
    if (!(checkDeleteMultiple || checkSwitchMultiple)) {
      let images = obj._def.extendedProps.images;
      let post = {
        id: obj.id,
        title: obj.title,
        start: obj.start,
        backgroundColor: obj.backgroundColor,
        images: images,
        client: selectedClient,
        socialNetworks: obj._def.extendedProps.socialNetworks,
        status: obj._def.extendedProps.status,
        createdBy: obj._def.extendedProps.createdBy,
        createdAt: obj._def.extendedProps.createdAt,
        hashtagsig: obj._def.extendedProps.hashtagsig,
        SK: obj._def.extendedProps.SK,
        updatedAt: obj._def.extendedProps.updatedAt,
        editedBy: obj._def.extendedProps.editedBy
      };
      dispatch(setSelectedPost(post));
      if (event.oldEvent === undefined) {
        viewPostBtn.current.click();
      } else {
        editPost(post);
      }
    } else {
      if (
        (obj.extendedProps.status === 'Schedule' ||
          obj.extendedProps.status === 'Draft') &&
        checkDeleteMultiple
      ) {
        if (listSelectedToDelete.indexOf(obj.id) === -1) {
          setListSelectedToDelete([...listSelectedToDelete, obj.id]);
        } else {
          let index = listSelectedToDelete.indexOf(obj.id);
          if (index > -1) {
            setListSelectedToDelete((listSelectedToDelete) =>
              listSelectedToDelete.filter((img, i) => i !== index)
            );
          }
        }
      } else if (
        (obj.extendedProps.status === 'Schedule' ||
          obj.extendedProps.status === 'Draft') &&
        checkSwitchMultiple
      ) {
        if (listSelectedtoSwitch.indexOf(obj.id) === -1) {
          setListSelectedtoSwitch([...listSelectedtoSwitch, obj.id]);
        } else {
          let index = listSelectedtoSwitch.indexOf(obj.id);
          if (index > -1) {
            setListSelectedtoSwitch((listSelectedtoSwitch) =>
              listSelectedtoSwitch.filter((img, i) => i !== index)
            );
          }
        }
      }
    }
  };
  const getStatusPost = async (id) => {
    if (queryDate && selectedClient.currentClientId !== '') {
      await fetchPosts(queryDate);
      const isSameId = (element) => element.id === id;
      const idx = postsLists.findIndex(isSameId);
      const post = { ...postsLists[idx], isDraft: false };
      dispatch(setSelectedPost(post));
    }
  };
  const getCalendarDate = async (
    fetchInfo,
    successCallback,
    failureCallback
  ) => {
    setQueryDate(
      `${new Date(fetchInfo.start).getMonth() + 1} ${new Date(
        fetchInfo.start
      ).getFullYear()}`
    );
    let postToCalendar = postsLists.map((post) => {
      let datePost = post.schedule || post.createdAt;
      let isDraft = post.isDraft;
      let datePost2 = moment.unix(datePost).format('YYYY-MM-DDTHH:mm');
      let dateNow = moment.utc(new Date()).unix();
      let statusPost = { status: 0, selected: checkDeleteMultiple };
      let valuesPost = {};
      let hashtagsig = post?.hashtagsig;
      if (isDraft) {
        statusPost.status = 2; //Draft
      } else if (post.createdBy === 'APP') {
        statusPost.status = 3;
      } else if (post?.prompt) {
        statusPost.status = 4;
      } else {
        statusPost.status = datePost > dateNow - 10 ? 1 : 0; //0 posted | 1 schedule
      }

      switch (true) {
        case statusPost.status === 0: // posted Status
          valuesPost['startEditable'] = false;
          valuesPost['status'] = 'Posted';
          valuesPost['borderColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.postedColor;
          valuesPost['backgroundColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.postedColor;
          break;

        case statusPost.status === 1: // scheduled Status
          valuesPost['startEditable'] =
            checkDeleteMultiple || checkSwitchMultiple ? false : true;
          valuesPost['status'] = 'Schedule';
          valuesPost['borderColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.scheduledColor;
          valuesPost['backgroundColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : listSelectedtoSwitch.includes(post.id) && checkSwitchMultiple
              ? Theme.infoColor
              : Theme.scheduledColor;
          break;

        case statusPost.status === 3: // APP Status
          valuesPost['startEditable'] =
            checkDeleteMultiple || checkSwitchMultiple ? false : true;
          valuesPost['status'] = 'App';
          valuesPost['borderColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.appColor;
          valuesPost['backgroundColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.appColor;
          break;
        case statusPost.status === 4:
          valuesPost['startEditable'] = true;
          valuesPost['status'] = 'Prompt';
          valuesPost['borderColor'] = Theme.promptColor;
          valuesPost['backgroundColor'] = Theme.promptColor;
          break;

        default:
          // Draft Status
          valuesPost['startEditable'] =
            checkDeleteMultiple || checkSwitchMultiple ? false : true;
          valuesPost['status'] = 'Draft';
          valuesPost['borderColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : Theme.draftColor;
          valuesPost['backgroundColor'] =
            listSelectedToDelete.includes(post.id) && checkDeleteMultiple
              ? Theme.deleteColor
              : listSelectedtoSwitch.includes(post.id) && checkSwitchMultiple
              ? Theme.infoColor
              : Theme.draftColor;
          break;
      }
      let start = getTime(selectedClient.timeZone, datePost2);
      return {
        id: post.id,
        title: post.message,
        start: start,
        startEditable: valuesPost['startEditable'],
        status: valuesPost['status'],
        borderColor: valuesPost['borderColor'],
        backgroundColor: valuesPost['backgroundColor'],
        textColor: '#fff',
        images: post.images,
        socialNetworks: post.socialNetworks,
        createdBy: post.createdBy,
        createdAt: post.createdAt,
        hashtagsig: hashtagsig,
        SK: post?.SK,
        updatedAt: post?.updatedAt,
        editedBy: post?.editedBy
      };
    });
    successCallback(postToCalendar);
  };

  const handleClickIATools = () => {
    IAPostTools.current.click();
  };

  const handleDateClick = (arg) => {
    if (checkDeleteMultiple || checkSwitchMultiple) {
      return;
    }
    var current = new Date(getTime(selectedClient.timeZone));

    var onNewPost = {
      title: '',
      start: new Date(
        arg.date.setHours(current.getHours(), current.getMinutes())
      ),
      allDay: false,
      backgroundColor: Theme.scheduledColor,
      borderColor: '#0168fa',
      textColor: '#fff'
    };
    setNewPost(onNewPost);

    addPostBtn.current.click();
  };
  const editPost = (post) => {
    setNewPost(post);
    addPostBtn.current.click();
  };
  const rePost = (post) => {
    let data = {};
    let socialNetwork = {};
    Object.assign(data, post);
    delete data.id;
    data.status = 'Schedule';
    Object.assign(socialNetwork, data.socialNetworks);
    if (data.socialNetworks.facebookPosted === true) {
      socialNetwork.facebook = false;
    }
    if (data.socialNetworks.twitterPosted === true) {
      socialNetwork.twitter = false;
    }
    if (data.socialNetworks.instagramPosted === true) {
      socialNetwork.instagram = false;
    }
    if (data.socialNetworks.googleMyBusinessPosted === true) {
      socialNetwork.googleMyBusiness = false;
    }
    data.socialNetworks = socialNetwork;
    setNewPost(data);
    addPostBtn.current.click();
  };

  const repairPost = (post) => {
    let data = {};
    Object.assign(data, post);
    data.status = 'Schedule';
    data.isRepairingPost = true;
    setNewPost(data);
    addPostBtn.current.click();
  };

  const deletePost = async (id, options) => {
    await axios
      .post(API_POSTS_DELETE, {
        postId: id,
        clientId: selectedClient.id,
        deletedBy: {
          displayName: userInfo?.displayName,
          email: userInfo?.email,
          role: userInfo?.role
        },
        options: options
      })
      .then((response) => {
        if (response.status === 200) {
          fetchPosts(queryDate);
        }
      });
  };

  const updatePost = (id) => {
    const isSameId = (element) => element.id === id;
    const idx = postsLists.findIndex(isSameId);
    const post = {
      ...postsLists[idx],
      createdBy: userInfo?.displayName,
      isDraft: !postsLists[idx].isDraft,
      posted: false,
      isScheduled: false
    };
    axios
      .post(API_POSTS_EDIT, JSON.stringify(post))
      .then((response) => {
        fetchPosts(queryDate);
      })
      .catch((error) => {
        fetchPosts(queryDate);
        if (error) {
          Swal.fire({
            title: 'error',
            text: 'We have trouble scheduling the publication'
          });
        }
        console.error(error);
      });
  };

  const updatePostsMultiple = () => {
    listSelectedtoSwitch.map((idPost) => {
      updatePost(idPost);
    });
    setListSelectedtoSwitch([]);
  };
  const deletePostMultiple = (options) => {
    listSelectedToDelete.map((idPost) => {
      deletePost(idPost, null);
    });
    setListSelectedToDelete([]);
  };

  const handleSelectAll = () => {
    let dateNow = moment.utc(new Date()).unix();
    const listFiltered = postsLists
      .map((post) => {
        return {
          id: post.id,
          isDraft: post.isDraft,
          posted: post.posted,
          createdBy: post.createdBy,
          schedule: post.schedule
        };
      })
      .filter((post) => {
        return post.createdBy !== 'APP' && post.schedule >= dateNow;
      })
      .map((post) => post.id);
    if (checkDeleteMultiple) {
      setListSelectedToDelete(listFiltered);
    } else if (checkSwitchMultiple) {
      setListSelectedtoSwitch(listFiltered);
    }
  };

  const handleChangeClient = (x) => {
    dispatch(setSelectedClient(x));
    setImagesLimit(3);
    let newUrl = x.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    history.push(`/calendar/${newUrl}`);
  };

  const [open, setOpen] = useState(false);
  const [clientModalId, setClientModalId] = useState(null);

  const handleNotesModal = useCallback((open, clientModalId) => {
    setOpen(!open);
    if (open) return setClientModalId(null);
    return setClientModalId(clientModalId);
  }, []);

  const carrouselModal = async (x, id) => {
    let idx = 0;
    for (
      idx = 0;
      idx < postsLists.length && selectedPost.id !== postsLists[idx].id;
      idx++
    );

    if (x === 1) {
      if (idx < postsLists.length - 1) {
        idx += 1;
      }
    } else if (x === 0) {
      if (idx > 0) {
        idx -= 1;
      }
    }
    let obj = postsLists[idx];
    if (x === 8) {
      let posts = await fetchPosts(queryDate);
      idx = 0;
      function findIndexByCondition(array, conditionFunction) {
        for (let i = 0; i < array.length; i++) {
          if (conditionFunction(array[i])) {
            return i; // Devuelve el índice cuando la condición se cumple
          }
        }
        return -1; // Si no se encuentra ningún elemento que cumpla la condición
      }
      const condition = (item) => item.id === id;
      idx = findIndexByCondition(posts, condition);

      obj = posts[idx];
    }

    let statCurrPost = '';
    let colorBackground = '';
    let datePost = obj?.schedule || obj?.createdAt;
    let dateNow = moment.utc(new Date()).unix();
    if (obj?.isDraft) {
      statCurrPost = 'Draft'; //Draft
      colorBackground = '#7987a1';
    } else if (obj?.createdBy === 'APP') {
      statCurrPost = 'App';
      colorBackground = Theme.appColor;
    } else if (showPrompts) {
      statCurrPost = 'Prompt';
    } else {
      statCurrPost = datePost > dateNow - 10 ? 'Schedule' : 'Posted'; //0 posted | 1 schedule
      colorBackground =
        statCurrPost === 'Posted' ? Theme.postedColor : Theme.scheduledColor;
    }

    let post = {
      id: obj?.id || id,
      title: obj?.message,
      start: obj?.schedule,
      backgroundColor: colorBackground,
      images: obj?.images,
      client: selectedClient,
      socialNetworks: obj?.socialNetworks,
      status: statCurrPost,
      createdBy: obj?.createdBy,
      createdAt: obj?.createdAt,
      hashtagsig: obj?.hashtagsig,
      SK: obj?.SK,
      updatedAt: obj?.updatedAt,
      editedBy: obj?.editedBy
    };
    dispatch(setSelectedPost(post));
  };

  const handleLoadingModal = useCallback(
    (open) => {
      dispatch(resetContentState());
      fetchPosts(queryDate);
    },
    [dispatch, fetchPosts, queryDate]
  );

  const viewPostBtn = useRef('#viewPost');
  const addPostBtn = useRef('#newPost');
  const IAPostTools = useRef('#iatools');

  let newUrl;
  if (selectedClient !== null) {
    newUrl = selectedClient.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
  }
  const createAIPost = async (postAI) => {
    const urlAI = API_GENERATE_AI_POST;
    const url = API_POSTS;
    try {
      // Generate AI post
      const response = await axios
        .post(urlAI, JSON.stringify(postAI))
        .catch((error) => {
          console.error(error);
        });

      // Schedule the new post
      axios.post(url, JSON.stringify(response.data.response)).catch((error) => {
        console.error('An error has ocurred scheduling new posts', error);
      });
    } catch (err) {
      return 'An error has ocurred scheduling new posts';
    }
    return 'OK';
  };

  const formatDate = (day, month, year) => {
    // Add 0 to 1 digit numbers
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    // Concatenate the date
    const formatedDate = `${formatedDay}/${formatedMonth}/${year}`;

    return formatedDate;
  };

  const generateContent = async () => {
    setIsGenerating(true);
    setError('');
    setTotalLoaded(0);
    setTotalDays(1);

    const postAI = {
      clientId: selectedClient.id,
      parcel: ''
    };

    const generate = async (post) => {
      let status = 'OK';
      status = await createAIPost(post);
      setTotalLoaded((prev) => prev + 1);
      return status;
    };

    const getNextMonthDates = (startDate) => {
      const dates = [];
      let currentDate = new Date(startDate);

      for (let i = 0; i < MONTHS_TO_GENERATE; i++) {
        // Generate dates depending of the month variable
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        for (let day = currentDate.getDate(); day <= lastDayOfMonth; day++) {
          dates.push(
            new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
          );
        }

        // Advance to the next month
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );
      }

      return dates;
    };

    const tomorrow = () => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    };

    const startDate = tomorrow();
    const datesToGenerate = getNextMonthDates(startDate);
    setTotalDays(datesToGenerate?.length || 1);
    let status = 'OK';
    for (const date of datesToGenerate) {
      postAI.parcel = formatDate(
        date.getDate(),
        date.getMonth(),
        date.getFullYear()
      );
      if (status === 'OK') {
        status = await generate(postAI);
        setError('');
      } else {
        setError(status);
      }
    }
    if (status === 'OK') {
      dispatch(resetContentState()); // to reset the state
      setTotalDays(1);
      setTotalLoaded(0);
      fetchPosts(queryDate);
    }
    setIsGenerating(false);
  };

  useEffect(() => {
    if (shouldGenerateContent && !isGenerating) {
      generateContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGenerateContent]);

  useEffect(() => {
    if (!selectedClient.clientName) return;

    if (typeof Storage !== 'undefined') {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();

      const yesterday = new Date(localStorage.getItem('prevDate'));
      const prevMonth = yesterday.getMonth() + 1;
      const prevDay = yesterday.getDate();

      if (
        currentMonth !== prevMonth ||
        currentDay !== prevDay ||
        !localStorage.getItem('prevDate')
      ) {
        localStorage.setItem('prevDate', today.toDateString());
        localStorage.removeItem('notes');
      }
    }

    if (!localStorage.getItem('notes')) {
      const clientsNotices = [];
      if (selectedClient.clientName !== '') {
        clientsNotices.push(selectedClient.clientName);
      }
      localStorage.setItem('notes', JSON.stringify(clientsNotices));
      setClientModalId(selectedClient.id);
      setOpen(true);
      return;
    }

    const clientsNotices = JSON.parse(localStorage.getItem('notes'));
    if (!clientsNotices.includes(selectedClient.clientName)) {
      clientsNotices.push(selectedClient.clientName);
      localStorage.setItem('notes', JSON.stringify(clientsNotices));
      setClientModalId(selectedClient.id);
      setOpen(true);
      return;
    }
    return () => {
      setClientModalId(null);
      setOpen(false);
    };
  }, [selectedClient]);

  const CustomListContent = (props) => {
    let post = props.obj.extendedProps;
    let id = props.obj.id;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <img
          style={{
            maxWidth: '180px',
            margin: '15px'
          }}
          src={post.images[0].url}></img>
        <div
          style={{
            height: '90px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          <div
            className="button-like-a nav-link"
            onClick={() => {
              onSelectPost(props.ev, true);
            }}>
            <i class="fas fa-eye fa-lg"></i>
          </div>
          {(post.status === 'Schedule' || post.status === 'Draft') &&
          !checkDeleteMultiple &&
          !checkSwitchMultiple ? (
            <>
              <div
                className="button-like-a nav-link"
                onClick={() => {
                  updatePost(id);
                }}>
                <i className="fas fa-lg fa-magic" />
              </div>
            </>
          ) : (
            <div
              style={{
                width: '50px'
              }}></div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="content ht-100v pd-0">
      <div className="content-header">
        <div className="content-search">
          <SearchIcon />
          <Select
            styles={customStyles}
            className="form-control"
            type="search"
            placeholder="Search clients"
            onChange={(x) => handleChangeClient(x)}
            options={customerList.map((customer) => ({
              ...customer,
              label: customer.clientName,
              value: customer.clientName
            }))}
            hideSelectedOptions={true}
            controlShouldRenderValue={false}
          />
        </div>
      </div>

      {/* <div className="row pb-2 mb-6">
        <div className="col-4">
          <div className="d-flex h-100 align-items-center justify-content-center gap-3">
            <div className="flex-grow-1 d-flex align-items-center gap-1">
              <input
                type="checkbox"
                disabled={checkSwitchMultiple}
                id="customCheckScheduledPosts"
                onClick={checkDeleteMultipleState}
                name="checkbox1"
                className="form-check-input"
              />
              <label htmlFor="terms1" className="form-check-label text-sm fw-medium">
                Delete Multiple {listSelectedToDelete.length}
              </label>
              <button
                disabled={checkSwitchMultiple}
                onClick={() => {
                  if (listSelectedToDelete.length > 0) {
                    Swal.fire({
                      title: 'Delete Posts',
                      text: 'Are you sure you want to delete these posts?',
                      showCloseButton: true,
                      showCancelButton: true
                    }).then((res) => {
                      if (res.value) {
                        deletePostMultiple();
                      }
                    });
                  }
                }}
                className="text-secondary"
              >
                trash
              </button>
            </div>
            <div className="flex-grow-1 d-flex align-items-center gap-1">
              <input
                type="checkbox"
                disabled={checkDeleteMultiple}
                id="mutipleSwitchPosts"
                onClick={checkSwitchMultipleState}
                name="checkbox1"
                className="form-check-input"
              />
              <label htmlFor="terms1" className="form-check-label text-sm fw-medium">
                Switch Multiple ({listSelectedtoSwitch.length})
              </label>
              <button
                disabled={checkDeleteMultiple}
                onClick={() => {
                  if (listSelectedtoSwitch.length > 0) {
                    Swal.fire({
                      title: 'Switch post status',
                      html: '<p>Are you sure you want to switch these posts status?<br/><br/><small>(Draft	&lt;-&gt; Scheduled)</small></p>',
                      showCloseButton: true,
                      showCancelButton: true
                    }).then((res) => {
                      if (res.value) {
                        updatePostsMultiple();
                      }
                    });
                  }
                }}
                className="text-secondary"
              >
                pencil
              </button>
            </div>
            {(checkDeleteMultiple || checkSwitchMultiple) && (
              <div>
                <button onClick={handleSelectAll} className="btn btn-primary">
                  Select All
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="col-8">
          <div className="d-flex h-100 align-items-center justify-content-between gap-3">
            <div className="d-flex align-items-center">
              <button className="btn btn-outline-secondary">
                left
              </button>
              <label className="mx-2" htmlFor="mutipleSwitchPosts">
                1/16
              </label>
              <button className="btn btn-outline-secondary">
                right
              </button>
            </div>

            <div className="d-flex gap-3">




            </div>


          </div>
        </div>
      </div> */}

      <div class="content-body pd-0">
        <NotesModal
          id={clientModalId}
          open={open}
          handleNotesModal={handleNotesModal}
        />
        <div id="calendar" class="calendar-content-body">
          <FullCalendar
            scrollTime={false}
            columnHeaderFormat={{ weekday: 'long' }}
            defaultView="dayGridMonth"
            selectable={true}
            eventLimit={false}
            displayEventTime={!showPrompts && !loadingPosts}
            fixedWeekCount={false}
            showNonCurrentDates={false}
            themeSystem={'bootstrap'}
            eventRender={(info) => {
              if (info.view.type === 'listMonth') {
                const titleTd = info.el.querySelector('.fc-list-item-title');
                const image = document.createElement('img');
                const reactContainer = document.createElement('div');
                ReactDOM.render(
                  <CustomListContent obj={info.event} ev={info} />,
                  reactContainer
                );
                image.src = info.event.extendedProps.images[0].url;
                image.style.maxWidth = '180px';
                image.style.margin = '15px';
                titleTd.style.display = 'flex';
                titleTd.style.justifyContent = 'space-between';
                reactContainer.style.display = 'flex';
                reactContainer.style.alignItems = 'center';
                titleTd.appendChild(reactContainer);
              }
              if (!showPrompts) return;
              if (info.view.type === 'dayGridMonth' && showPrompts) {
                const eventElement = info.el;
                const customHeight = '128px';
                info.el.style.height = customHeight;
                info.el.style['w'] = '';
                const content = eventElement.querySelector('.fc-content');
                content.classList.add('fc-content-custom');
              }
            }}
            customButtons={{
              ...(true && {
                promptsCalendar: {
                  text: showPrompts ? 'Show Posts' : 'Show prompts',
                  click: handleShowPrompts
                }
              }),
              ...((userInfo?.role === 'superAdmin' ||
                userInfo?.email === 'deborah@adlersocial.com' ||
                userInfo?.email === 'christiana@adlersocial.com' ||
                userInfo?.email === 'esther@adlersocial.com' ||
                userInfo?.email === 'deannie@adlersocial.com' ||
                userInfo?.email === 'phillippa@adlersocial.com' ||
                userInfo?.email === 'jose@adlersocial.com' ||
                userInfo?.email === 'heather@adlersocial.com') && {
                genContent: {
                  text: 'Generate AI Content',
                  click: async () => {
                    dispatch(showLoadingModal());
                  }
                }
              }),
              customTitle: {
                text: `Prompts${
                  contentStrategy?.strategyName !== undefined
                    ? ' - ' + contentStrategy?.strategyName
                    : ''
                }`
              }
            }}
            height={'100%'}
            buttonIcons={{
              prev: 'left-single-arrow',
              next: 'right-single-arrow',
              prevYear: 'left-double-arrow',
              nextYear: 'right-double-arrow'
            }}
            views={{
              FourDayView: {
                type: 'timeGrid',
                duration: { days: 4 },
                buttonText: '4 day'
              },
              YearView: {
                type: 'timeline',
                buttonText: 'year',
                dateIncrement: { years: 1 },
                slotDuration: { months: 1 },
                duration: { year: 1 }
              }
            }}
            eventClick={onSelectPost}
            eventDrop={(event) => {
              if (showPrompts) return;
              let start;
              if (typeof event.event.start === 'object') {
                start = new Date(event.event.start).getTime();
                start = start / 1000;
                start = Math.floor(start);
              } else {
                start = event.event.start;
              }
              let getIdxPost = () => {
                let idx = 0;
                while (postsLists[idx].id !== event.event.id) {
                  idx++;
                }
                return idx;
              };
              let isDraft = false;
              let posted = false;
              let isScheduled = false;
              if (event.event.extendedProps.status === 'Draft') {
                isDraft = true;
              } else if (event.event.extendedProps.status === 'Posted') {
                posted = true;
              } else if (event.event.extendedProps.status === 'Scheduled') {
                isScheduled = true;
              } else {
              }
              let post = {
                message: event.event.title,
                images: event.event.extendedProps.images,
                schedule: start,
                createdAt: event.event.extendedProps.createdAt,
                socialNetworks: event.event.extendedProps.socialNetworks,
                isDraft,
                recurrentDates: [],
                posted,
                isScheduled,
                createdBy: event.event.extendedProps.createdBy,
                clientId: selectedClient.id,
                postId: event.event.id
              };
              let newPostsList = postsLists;
              let idx = getIdxPost();

              newPostsList[idx] = post;
              SetPostsLists(newPostsList);
              SetLoadingPosts(true);
              axios
                .post(API_POSTS_EDIT, JSON.stringify(post))
                .then((response) => {
                  fetchPosts(queryDate);
                })
                .catch((error) => {
                  fetchPosts(queryDate);
                  if (error) {
                    Swal.fire({
                      title: 'error',
                      text: 'We have trouble scheduling the publication'
                    });
                  }
                  console.error(error);
                });
            }}
            buttonText={{
              today: 'Today',
              month: 'Month',
              dayGridWeek: 'Week',
              day: 'Day',
              list: 'List'
            }}
            header={{
              left: 'prev,next, today',
              center: `${showPrompts ? 'customTitle' : 'title'}`,
              right:
                'promptsCalendar, genContent, dayGridDay, dayGridWeek, dayGridMonth, listMonth'
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              timeline,
              list,
              bootstrap
            ]}
            events={(fetchInfo, successCallback, failureCallback) =>
              getCalendarDate(fetchInfo, successCallback, failureCallback)
            }
            dateClick={handleDateClick}
            forceEventDuration={true}
            defaultTimedEventDuration={{ minutes: 1 }}
          />
          <LoadingModal
            open={shouldGenerateContent}
            handleModal={handleLoadingModal}
            percent={Number((totalLoaded * 100) / totalDays).toFixed(0)}
            error={error}
          />
        </div>
        {/* <div className="modal-posts-calendar">
            <ViewPostsInCalendar
              contentStrategy={contentStrategy}
              userRole={userInfo}
              posts={postsLists}
              selectedClient={selectedClient}
              carrouselModal={carrouselModal}
              updatePost={updatePost}
              handleShowPrompts={handleShowPrompts}
              deletePost={(id, options) => {
                deletePost(id, options);
              }}
              editPost={(post) => {
                editPost(post);
              }}
              rePost={(post) => {
                rePost(post);
              }}
              repairPost={(post) => {
                repairPost(post);
              }}
              IAPostTools={() => {
                handleClickIATools();
              }}
              post={selectedPost}
              timeZone={getTime(selectedClient.timeZone)}
              time={selectedClient.timeZone}
              getStatusPost={getStatusPost}
              close={() => {
                dispatch(setSelectedPost());
              }}
            />
          </div>
          <NewPost
            post={newPost}
            hideRecurrent={true}
            isCalendar={true}
            callbackPost={(post, used_state) => {
              if (post) {
                fetchPosts(queryDate);
                setNewPost(undefined);
              } else {
                setNewPost(undefined);
              }
              if (used_state) {
                setRefresh(!refresh);
              }
            }}
            currentClientId={selectedClient.id}
            currentClientSocialNetworks={
              selectedClient.clientSocialNetworks
            }
            currentClientTimeZone={selectedClient.timeZone}
            currentClientBestTimes={selectedClient.bestTimes}
            currentClientMergeFields={selectedClient.mergeFields}
            currentCustomerName={userInfo.displayName}
          />
          {selectedPost && (
            <AIToolsPost
              fetchPosts={fetchPosts}
              queryDate={queryDate}
              post={selectedPost}
            />
          )} */}
      </div>
    </div>
  );
}

export default CalendarScreen;
