// Layout.js
import React from 'react';
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
  return (
    <>
      <aside className="aside aside-fixed">
        <div className="aside-header">
          <a href="../../index.html" className="aside-logo">
            dash<span>forge</span>
          </a>
          <a href="" className="aside-menu-link">
            <i data-feather="menu"></i>
            <i data-feather="x"></i>
          </a>
        </div>
        <div className="aside-body">
          <div className="aside-loggedin">
            <div className="d-flex align-items-center justify-content-start">
              <a href="" className="avatar">
                <img
                  src="https://placehold.co/387"
                  className="rounded-circle"
                  alt=""
                />
              </a>
              <div className="aside-alert-link">
                <a
                  href=""
                  className="new"
                  data-bs-toggle="tooltip"
                  title="You have 4 new notifications">
                  <i data-feather="bell"></i>
                </a>
                <a href="" data-bs-toggle="tooltip" title="Sign out">
                  <i data-feather="log-out"></i>
                </a>
              </div>
            </div>
            <div className="aside-loggedin-user">
              <a
                href="#loggedinMenu"
                className="d-flex align-items-center justify-content-between mg-b-2"
                data-bs-toggle="collapse">
                <h6 className="tx-semibold mg-b-0">Daniel Dominguez</h6>
                <i data-feather="chevron-down"></i>
              </a>
              <p className="tx-color-03 tx-12 mg-b-0">Administrator</p>
            </div>
            <div className="collapse" id="loggedinMenu">
              <ul className="nav nav-aside mg-b-0">
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <i data-feather="edit"></i> <span>Edit Profile</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <i data-feather="user"></i> <span>View Profile</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <i data-feather="settings"></i>{' '}
                    <span>Account Settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <i data-feather="help-circle"></i> <span>Help Center</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <i data-feather="log-out"></i> <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ul className="nav nav-aside">
            <li className="nav-label">Dashboard</li>
            <li className="nav-item active">
              <a href="" className="nav-link">
                <i data-feather="shopping-bag"></i> <span>Clients</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="" className="nav-link">
                <i data-feather="globe"></i> <span>Reviews</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="" className="nav-link">
                <i data-feather="pie-chart"></i> <span>Content</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="" className="nav-link">
                <i data-feather="life-buoy"></i> <span>Images ......</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      {children}
    </>
  );
};

export default Layout;
