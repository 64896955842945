import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/slices/auth';
import { REACT_APP_API_NOTIFICATIONS } from '../../utils';
import Header from '../../components/Header';
import DataTables from '../../components/DataTables';
import ModalInfo from './ModalInfo';

const Login = (props) => {
  const { userInfo } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);

  const getLogs = () => {
    setLoading(true);
    const email = userInfo.email; // TODO: userInfo.email
    axios
      .get(`${REACT_APP_API_NOTIFICATIONS}/all?email=${email}`)
      .then((res) => {
        const logs = res.data;
        setLogs([
          ...logs.map((log) => {
            return { ...log, id: uuid() };
          })
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowModal = (info) => {
    setShowModal(!showModal);
    if (info) {
      setModalInfo(info);
    } else {
      setModalInfo(null);
    }
  };

  useEffect(() => {
    getLogs();
  }, []);

  const DateFormat = (cell, row) => {
    if (cell && !row.rejected) {
      return new Date(cell).toLocaleDateString();
    } else {
      return <>--</>;
    }
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.is_read === false) {
      style.fontWeight = 'bold';
    }

    if (rowIndex === highlightedRow) {
      style.fontWeight = 'normal'; // Cambia este color según prefieras
    }

    return style;
  };

  const handleCheckNotification = async (row, rowIndex) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        id: row.notificationId
      })
    };

    await fetch(`${REACT_APP_API_NOTIFICATIONS}`, options);

    setHighlightedRow(rowIndex);
    row.is_read = true;
  };

  const logsColumns = [
    {
      dataField: 'name',
      text: 'name',
      headerStyle: () => {
        return {
          width: '281px',
          textAlign: 'left'
        };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            key={row.clientId + uuid()}>
            <span
              style={{
                cursor: 'pointer',
                color: '#3b5998'
              }}
              onClick={() => {
                handleShowModal(row);
                handleCheckNotification(row, rowIndex);
              }}>
              {cell ? cell : 'undefined'}
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'createdAt',
      text: 'date',
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'left'
        };
      },
      formatter: DateFormat
    },
    {
      dataField: 'time',
      text: 'time',
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'left'
        };
      },
      formatter: (cell, row) => {
        return new Date(cell).toLocaleTimeString();
      }
    },
    {
      dataField: 'type',
      text: 'module',
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'left'
        };
      }
    },
    {
      dataField: 'title',
      text: 'message text',
      formatter: (cellContent) => (
        <div dangerouslySetInnerHTML={{ __html: cellContent }} />
      )
    }
  ];
  return (
    <>
      <Header loading={loading} />
      <div
        className="content content-fixed"
        style={{
          height: 'calc(100vh - 60px)',
          overflow: 'auto',
          padding: '0 1px 0 1px'
        }}>
        <DataTables
          buttonsCallback={() => {}}
          userRole={userInfo.role}
          data={logs}
          columns={logsColumns}
          noDataIndication={loading ? 'Loading...' : 'No Logs'}
          handleModal={() => {}}
          rowStyle={rowStyle}
          view={'logs'}
          alternTitle="logs"
        />
        {showModal && (
          <ModalInfo handleClose={handleShowModal} logInfo={modalInfo} />
        )}
      </div>
    </>
  );
};

export default Login;
