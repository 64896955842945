import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DataTables from '../../components/DataTables';
import NewClientOnboarding from '../../components/NewClientOnboarding/NewClientOnboarding';
import NewFacebookPageConnection from '../../components/NewFacebookPageConnection';
import NewZohoConnection from '../../components/NewZohoConnection';
import NewGoogleConnection from '../../components/NewGoogleConnection';
import NewInstagramConnection from '../../components/NewInstagramConnection';
import NewPost from '../../components/NewPost';
import { setSelectedClient } from '../../store/slices/selectedClient';
import AppRegister from '../../components/AppRegister';
import BulkModal from '../../components/BulkModal';
import { getClients } from '../../store/slices/clients';
import ButtonsGroup from '../../components/ButtonsGroup';

const Dashboard = (props) => {
  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentClientTimeZone, setCurrentClientTimeZone] = useState(null);
  const [currentClientBestTimes, setCurrentClientBestTimes] = useState(null);
  const [currentClientMergeFields, setCurrentClientMergeFields] =
    useState(null);
  const [currentClientSocialNetworks, setCurrentClientSocialNetworks] =
    useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const instagramPages = [];
  const [appRegister, setAppRegister] = useState({
    show: false,
    data: null
  });
  const [loading, setLoading] = useState(false);
  const [newPost, setNewPost] = useState({ show: false });
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const validNetworks = (socialnetworks) => {
    return (
      socialnetworks.googleMyBusiness ||
      socialnetworks.facebook ||
      socialnetworks.twitter ||
      socialnetworks.instagram
    );
  };
  const callBackClose = () => {
    setNewPost({ show: false });
  };

  const handleCalendarClick = (row) => {
    props.dispatch(setSelectedClient(row));
    let newUrl = row.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    props.history.push(`/calendar/${newUrl}`);
  };

  const clientActions = (cell, row) => {
    if (row.id) {
      let validAnySn = validNetworks(row?.clientSocialNetworks);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <nav className="nav nav-icon-only ">
            {row.clientSocialNetworks ? (
              <a
                href={validAnySn ? '#newPost' : ''}
                className=""
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false"
                onClick={() => {
                  setNewPost({ show: true });
                  dataClientHandler(
                    row.id,
                    row.clientSocialNetworks,
                    row.timeZone,
                    row.bestTimes,
                    row.mergeFields
                  );
                }}>
                <i
                  className="far fa-fw fa-plus-square"
                  style={{ color: 'rgb(131, 146, 165)', fontSize: '24px' }}
                />
              </a>
            ) : (
              <a
                href="#noSocialNetworkAvailable"
                className=""
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false">
                <i className="far fa-2x fa-fw fa-plus-square" />
              </a>
            )}
            <button
              className=" button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Go to client calendar"
              onClick={() => handleCalendarClick(row)}>
              <i
                className="fa fa-fw fa-calendar-alt"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '24px' }}
              />
            </button>

            <a
              onClick={() =>
                dataClientHandler(
                  row.id,
                  row.clientSocialNetworks,
                  row.timeZone,
                  row.bestTimes
                )
              }
              href={validAnySn ? '#BulkModal' : ''}
              className=""
              data-toggle="modal"
              data-animation="effect-scale"
              data-backdrop="static"
              data-keyboard="false"
              title="Bulk posts">
              <i
                className="fa fa-fw fa-file-excel"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '24px' }}
              />
            </a>
          </nav>
        </div>
      );
    }
  };

  const clientManagement = (cell, row) => {
    if (row.id) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <nav className="nav nav-icon-only">
            <button
              className="button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Got to client's profile"
              onClick={() => handleCalendarClick(row)}>
              <i
                className="fa fa-fw fa-pencil-alt"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '22px' }}
              />
            </button>
            <button
              className="button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete client"
              onClick={() => handleCalendarClick(row)}>
              <i
                className="fa fa-fw fa-trash-alt"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '22px' }}
              />
            </button>
          </nav>
        </div>
      );
    }
  };

  const renderZohoIcon = (active) => {
    let iconStyle = {
      width: 23,
      height: 23,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/logo-crm.png`
          : `${process.env.PUBLIC_URL}/assets/img/logo-crm-disconnected.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const clientSocialNetworks = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            {row.clientSocialNetworks ? (
              <>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newFacebookPageConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.facebook
                        ? { color: '#3b5998' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-facebook" />
                    {row.clientSocialNetworks.fbFailing &&
                    row.clientSocialNetworks.facebook ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newGoogleConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.googleMyBusiness
                        ? { color: '#4284F5' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-google" />
                    {row.clientSocialNetworks.gmbFailing &&
                    row.clientSocialNetworks.googleMyBusiness ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newInstagramConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.instagram
                        ? { color: '#8a3ab9' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-instagram" />
                    {row.clientSocialNetworks.igFailing &&
                    row.clientSocialNetworks.instagram ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <a
                  href="#newZohoConnection"
                  className="dropdown-link new-indicator "
                  style={{
                    marginLeft: '5px',
                    marginTop: '3px'
                  }}
                  data-toggle="modal"
                  data-animation="effect-scale"
                  onClick={() => setCurrentClientId(row.id)}
                  data-backdrop="static"
                  data-keyboard="false">
                  <button
                    className=" button-like-a"
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => setCurrentClientId(row.id)}>
                    {renderZohoIcon(row.clientSocialNetworks?.zoho)}
                  </button>
                </a>
              </>
            ) : (
              <p style={{ color: 'red' }}>
                Error trying to load social networks, please contact support
              </p>
            )}
          </nav>
        </>
      );
    }
  };

  const clientName = (cell, row) => {
    if (row.id) {
      let newUrl = row.clientName
        .replace(/\s+/g, '%20')
        .replace(/\//g, '%2F')
        .replace(/'/g, '%1D')
        .replace(/’/g, '%1E');
      return (
        <div>
          <Link
            onClick={() => props.dispatch(setSelectedClient(row))}
            onMouseDown={() => props.dispatch(setSelectedClient(row))}
            to={{
              pathname: `/client/${newUrl}`,
              state: {
                clientId: row.id,
                clientGroups: row.clientGroups,
                socialNetworks: row['clientSocialNetworks']
              }
            }}>
            <span>{row['clientName']}</span>
          </Link>
        </div>
      );
    }
  };

  const renderAppIcon = (active) => {
    let iconStyle = {
      width: 30,
      height: 30,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/sms.png`
          : `${process.env.PUBLIC_URL}/assets/img/smsf.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const mobileApp = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            <button
              className=" button-like-a"
              style={{ marginRight: 10 }}
              onClick={() => setAppRegister({ show: true, data: row })}>
              {renderAppIcon(row.appActive)}
            </button>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                position: 'relative',
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{typeof row.smsCount === 'undefined' ? '0' : row.smsCount}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{row.countYelp ? row.countYelp.clicks : 0}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{row.countGmb ? row.countGmb.clicks : 0}</p>
            </div>
          </nav>
        </>
      );
    }
  };
  // Update table only if differences un clients list

  // Fetch all clients to listen for changes
  const undercoverGetClients = async () => {
    setLoading(true);
    try {
      await props.dispatch(getClients());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    undercoverGetClients();
    // eslint-disable-next-line
  }, []);

  const myCallback = (dataFromChild) => {
    undercoverGetClients();
  };

  const clientColumns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'clientName',
      text: 'Client Name',
      headerStyle: () => {
        return {
          width: '281px',
          textAlign: 'left',
          marginLeft: '10px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left',
          marginLeft: '10px'
        };
      },
      formatter: clientName
    },
    {
      dataField: 'dummy-sn',
      text: 'Social Networks',
      formatter: clientSocialNetworks,
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'dummy-actions',
      text: 'Actions',
      formatter: clientActions,
      headerStyle: () => {
        return {
          width: '150px',
          textAlign: 'center',
          alignItems: 'center'
        };
      }
    },
    {
      dataField: 'appActive',
      text: 'App - #SMS  Yelp  GMB',
      headerStyle: () => {
        return { width: '160px', textAlign: 'center' };
      },
      formatter: mobileApp
    },
    {
      dataField: 'dummy-actions',
      text: 'Management',
      formatter: clientManagement,
      headerStyle: () => {
        return {
          width: '150px',
          textAlign: 'center',
          alignItems: 'center'
        };
      }
    }
  ];

  const connectSnCallback = () => {
    myCallback();
  };

  const dataClientHandler = (
    clientId,
    socialnetworks,
    timeZone,
    bestTimes,
    mergeFields
  ) => {
    setCurrentClientId(clientId);
    setCurrentClientSocialNetworks(socialnetworks);
    setCurrentClientTimeZone(timeZone);
    setCurrentClientBestTimes(bestTimes);
    setCurrentClientMergeFields(mergeFields);
    let validSn = validNetworks(socialnetworks);
    if (!validSn) {
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'Sync Any Social Network.'
      });
    }
  };
  const userRole = props.role;
  let view = {};

  return (
    <>
      <div className="content ht-100v pd-0">
        <div className="content-header">
          <div className="content-search">
            <i data-feather="search"></i>
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
            />
          </div>
        </div>

        <div>
          <div>
            <div className="p-3 align-items-center justify-content-between">
              <div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">
                      <a href="#">Clients</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h4 className="mg-b-0 tx-spacing--1">Clients</h4>
              </div>

              {/* <div className="d-none d-md-block">
                <button className="btn btn-sm pd-x-15 btn-white btn-uppercase">
                  <i data-feather="mail" className="wd-10 mg-r-5"></i> Email
                </button>
                <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                  <i data-feather="printer" className="wd-10 mg-r-5"></i> Print
                </button>
                <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                  <i data-feather="file" className="wd-10 mg-r-5"></i> Generate
                  Report
                </button>
              </div> */}
            </div>

            <div class="row row-xs">
              <div
                style={{
                  height: 'calc(90vh - 60px)',
                  overflow: 'auto',
                  padding: '0 1px 0 1px'
                }}>
                <DataTables
                  buttonsCallback={connectSnCallback}
                  userRole={userRole}
                  data={props.customerList}
                  columns={clientColumns}
                  noDataIndication={'No Clients'}
                  handleModal={handleShow}
                  showTotal={true}
                />
                {/* <ButtonsGroup
                  view={view}
                  viewState={viewState}
                  connectCallback={buttonsCallback}
                  userRole={userRole}
                  handleModal={handleModal}
                  loading={loading}
                  showTotal={showTotal}
                /> */}
              </div>
              <NewClientOnboarding
                callbackFromParent={myCallback}
                showModal={showModal}
                handleClose={handleClose}
              />
              <NewFacebookPageConnection
                currentClientId={currentClientId}
                currentClient={currentClient}
                connectSnCallback={connectSnCallback}
              />
              <NewZohoConnection
                currentClientId={currentClientId}
                currentClient={currentClient}
                connectSnCallback={connectSnCallback}
              />
              <NewGoogleConnection
                currentClientId={currentClientId}
                currentClient={currentClient}
                connectSnCallback={connectSnCallback}
              />
              <NewInstagramConnection
                currentClientId={currentClientId}
                currentClient={currentClient}
                instagramPages={instagramPages != null}
                connectSnCallback={connectSnCallback}
              />
              {newPost.show && (
                <NewPost
                  show={newPost.show}
                  currentClientId={currentClientId}
                  currentClientSocialNetworks={currentClientSocialNetworks}
                  currentClientTimeZone={currentClientTimeZone}
                  currentClientBestTimes={currentClientBestTimes}
                  currentClientMergeFields={currentClientMergeFields}
                  currentCustomerName={props.customerName}
                  callBackClose={callBackClose}
                />
              )}
              {appRegister.show && (
                <AppRegister
                  data={appRegister.data}
                  onUpdate={() => {
                    myCallback();
                  }}
                  handleClose={() =>
                    setAppRegister({ appRegister: { show: false } })
                  }
                />
              )}
              <BulkModal
                title={'posts'}
                currentClientId={currentClientId}
                currentClientSocialNetworks={currentClientSocialNetworks}
                currentClientTimeZone={currentClientTimeZone}
                currentCustomerName={props.customerName}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  let { errorLogin, loading, userInfo } = state.auth;
  let { loadingClients, errorClients, customerList } = state.clients;
  return {
    customerName: userInfo?.displayName,
    customerEmail: userInfo?.email,
    customerPhoto: userInfo?.photoURL,
    role: userInfo?.role,
    isLoggingOut: loading,
    logoutError: errorLogin,
    loadingClients,
    errorClients,
    customerList
  };
}
export default connect(mapStateToProps)(Dashboard);
