import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalInfo = ({ logInfo = {}, handleClose = () => {} }) => {
  const extraerEnlaceImagen = (texto) => {
    const regex =
      /<a target=\"_blank\" href=\"(https?.+\.(?:jpg|jpeg|png|gif))\">/i;
    const coincidencia = texto.match(regex);
    return coincidencia ? coincidencia[1] : null;
  };

  const enlaceImagen = extraerEnlaceImagen(logInfo.title);

  return (
    <Modal
      id="viewLog"
      backdrop="static"
      keyboard={false}
      show={true}
      onHide={() => {}}>
      <Modal.Header
        style={{
          backgroundColor: '#3B5998'
        }}>
        <h5
          style={{ color: 'white' }}
          className="modal-title"
          id="addNewClientLabel">
          Log ID: {logInfo.id || '--'}
        </h5>
        <button
          onClick={() => handleClose()}
          style={{
            color: 'white'
          }}
          className="close"
          type="button">
          <span style={{ color: 'white' }} aria-hidden="true">
            ×
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div className="row row-sm">
            <div className="col-sm-6">
              <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                Date & Time
              </label>
              <p className="event-start-date" style={{ color: '#8392A5' }}>
                {logInfo.createdAt
                  ? `${new Date(logInfo.createdAt).toLocaleDateString()}`
                  : '--'}
              </p>
              <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                Module
              </label>
              <p>{logInfo.type || '--'}</p>
            </div>

            <div id="timestatus" className="col-sm-6">
              <div>
                <>
                  <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
                    Level
                  </label>
                  <br />
                  <p style={{ color: '#3B5998' }}>{logInfo.level || 'INFO'}</p>
                </>
              </div>
            </div>
          </div>

          <br />
          <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
            Message Text
          </label>
          <p dangerouslySetInnerHTML={{ __html: logInfo.title }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              src={enlaceImagen}
              alt="Imagen cargada desde el texto"
              style={{ maxWidth: '80%' }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
          }}>
          <button
            className={'btn btn-secondary pd-x-20'}
            disabled={false}
            data-dismiss="modal"
            ref={(button) => {
              //   setModalDismiss(button);
            }}
            onClick={() => {
              handleClose();
            }}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfo;
