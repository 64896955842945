import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';
import TagsInput from './TagsInput';
import axios from 'axios';
import {
  API_GROUPS,
  API_NEWCLIENT,
  API_GET_CONTENT_STRATEGIES,
  API_KEY,
  API_CLIENTS_NAME,
  GET_PRIMARY_SERVICES,
  API_GET_PLANS
} from '../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { generateContent } from '../../store/slices/generalState';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { daysOrder } from '../../utils';
import { Modal } from 'react-bootstrap';
import './NewClientOnboarding.css';
import BusinessHours from './BusinessHours';
import { BlockPicker } from 'react-color';
import {
  AWS_MMS_S3_ACCESS_KEY,
  AWS_MMS_S3_API_KEY,
  AWS_REGION,
  AWS_BUCKET_CLIENT_LOGOS
} from '../../utils/constants';
import { v4 as uuid } from 'uuid';
import S3 from 'react-aws-s3';
import Swal from 'sweetalert2';
import { setSelectedClient } from '../../store/slices/selectedClient';

const NewClientOnboarding = ({
  showModal,
  handleClose,
  callbackFromParent,
  defaultData = {}
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currStep, setCurrStep] = useState(1);
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    clientName: '', // String required
    timeZone: '', // String required
    serviceArea: [], // list of Strings optional
    secondaryServiceArea: [],
    description: '', // String optional
    businessHours: {
      // Object with keys required and values for business hours as strings with format 'number/number' or just '/'
      monday: '9/18',
      tuesday: '9/18',
      wednesday: '9/18',
      thursday: '9/18',
      friday: '9/18',
      saturday: '9/18',
      sunday: '9/18'
    },
    mergeFields: {
      // Object with keys required and values as strings
      '~Address': '',
      '~Phone': '',
      '~Website': '',
      '~Email': ''
    },
    industry: '', // String required
    contentStrategy: '', // String required
    primaryService: [], // list of Strings optional
    additionalService: [], // list of Strings optional
    attributes: [], // list of Strings optional
    promotions: [], // list of Strings optional
    bestTimes: {
      // Object required with days as keys and strings as values (those strings have a format like '00' )
      Monday: '07',
      Tuesday: '07',
      Wednesday: '07',
      Thursday: '07',
      Friday: '07',
      Saturday: '07',
      Sunday: '07'
    },
    holidays: [], // list of strings optional
    igTags: [], // list of strings optional
    fbTags: [], // list of strings optional
    negativeKeywords: [], // list of strings required
    galleries: {
      //object with those keys and values optionals but if enabled the url is required
      stockPhotos: {
        enabled: true
      },
      appLibrary: {
        enabled: false
      }
    },
    logoUrl: '', // string optional
    brand: {
      // object optional
      primaryColor: '#3B5998',
      secondaryColor: '#8392A5'
    },
    plan: ''
  };
  const userInfo = useSelector((state) => state.auth.userInfo);
  const timeZones = [
    { value: 1, label: 'Eastern Daylight Time - (GMT-4)' },
    { value: 2, label: 'Central Daylight Time - (GMT-5)' },
    { value: 3, label: 'Mountain Daylight Time - (GMT-6)' },
    { value: 4, label: 'Mountain Standard Time - (GMT-7)' },
    { value: 5, label: 'Pacific Daylight Time - (GMT-7)' },
    { value: 6, label: 'Alaska Daylight Time - (GMT-8)' },
    { value: 7, label: 'Hawaii-Aleutian Standard Time - (GMT-10)' }
  ];
  const attributeList = [
    { value: 1, label: 'Curbside Pickup' },
    { value: 2, label: 'Deliver' },
    { value: 3, label: 'Dine-in' },
    { value: 4, label: 'Drive through' },
    { value: 5, label: 'No-contact delivery' },
    { value: 6, label: 'Outdoor seating' },
    { value: 7, label: 'Takeout' },
    { value: 8, label: 'Assistive hearing loop' },
    { value: 9, label: 'Beach wheelchairs' },
    { value: 10, label: 'Mobility scooter rental' },
    { value: 11, label: 'Passenger loading area' },
    { value: 12, label: 'Wheelchair-accessible elevator' },
    { value: 13, label: 'Wheelchair-accessible entrance' },
    { value: 14, label: 'Wheelchair-accessible parking' },
    { value: 15, label: 'Wheelchair-accessible restroom' },
    { value: 16, label: 'Wheelchair-accessible seating' },
    { value: 17, label: 'Adults only' },
    { value: 18, label: 'Family-friendly' },
    { value: 19, label: 'Kid-friendly' },
    { value: 20, label: 'LGBTQ+ friendly' },
    { value: 21, label: 'Transgender safespace' },
    { value: 22, label: 'Accepting new patients' },
    { value: 23, label: 'Accepts reservations' },
    { value: 24, label: 'Appointment required' },
    { value: 25, label: 'Membership required' },
    { value: 26, label: 'Online events calendar' },
    { value: 27, label: 'Reservations recommended' },
    { value: 28, label: 'Reservations required' },
    { value: 29, label: 'Beach access' },
    { value: 30, label: 'Bicycles for rent' },
    { value: 31, label: 'Boats for rent' },
    { value: 32, label: 'Game room' },
    { value: 33, label: 'Horseback riding' },
    { value: 34, label: 'Jogging track' },
    { value: 35, label: 'Nightclub' },
    { value: 36, label: 'Scuba diving' },
    { value: 37, label: 'Snorkeling' },
    { value: 38, label: 'Tennis' },
    { value: 39, label: 'Water skiing' },
    { value: 40, label: 'Identifies as black owned' },
    { value: 41, label: 'Identifies as veteran-led' },
    { value: 42, label: 'Identifies as women-led' },
    { value: 43, label: 'Cash Only' },
    { value: 44, label: 'Checks' },
    { value: 45, label: 'Debit Cards' },
    { value: 46, label: 'NFC Mobile Payments' },
    { value: 47, label: 'American Express' },
    { value: 48, label: 'Master Card' },
    { value: 49, label: 'Visa' },
    { value: 50, label: 'Discover' },
    { value: 41, label: 'Diners club' }
  ];
  const customMultiSelectorStyle = {
    dropdownIndicator: () => ({
      display: 'none'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    control: (provided) => ({
      ...provided,
      width: '100%'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#3B5998',
      color: 'black',
      borderRadius: '0px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      '&:hover': {
        backgroundColor: '#D9D9D9',
        color: 'black'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 75,
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: 'darkgray lightgray',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgray',
        borderRadius: '8px'
      }
    })
  };
  const holidays = [
    { value: 1, label: "New Year's Day" },
    { value: 2, label: 'Martin Luther King Jr. Day' },
    { value: 3, label: "Valentine's Day" },
    { value: 4, label: "Presidents' Day" },
    { value: 5, label: 'Easter' },
    { value: 6, label: 'Memorial Day' },
    { value: 7, label: 'Independence Day' },
    { value: 8, label: 'Labor Day' },
    { value: 9, label: 'Columbus Day' },
    { value: 10, label: 'Halloween' },
    { value: 11, label: 'Veterans Day' },
    { value: 12, label: 'Thanksgiving' },
    { value: 13, label: 'Christmas' }
  ];

  const validationSchema = yup.object().shape({
    clientName: yup.string().required('Client name is required'),
    timeZone: yup.string().required('Time zone is required'),
    serviceArea: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A service area has an invalid format'
          )
      ),
    secondaryServiceArea: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A secondary service area has an invalid format'
          )
      ),
    // .min(1, 'At least one service area is required'),
    description: yup.string(),
    businessHours: yup.object().shape({
      monday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      tuesday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      wednesday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      thursday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      friday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      saturday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      sunday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        })
    }),
    mergeFields: yup.object().shape({
      '~Address': yup.string(),
      '~Phone': yup.string(),
      '~Website': yup.string(),
      '~Email': yup.string(),
      '~City': yup.string(),
      '~Name': yup.string(),
      '~BestDish': yup.string(),
      '~BestService': yup.string(),
      '~BestProduct': yup.string()
    }),
    industry: yup.string(),
    contentStrategy: yup.string(),
    primaryService: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s',.~]+$/,
            'A primary has an invalid format'
          )
      ),
    // .min(1, 'At least one primary service is required'),
    additionalService: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s',.~]+$/,
            'An additional service has an invalid format'
          )
      ),
    attributes: yup.array().of(
      yup.string()
      // .matches(
      //   /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
      //   'An attribute has an invalid format'
      // )
    ),
    promotions: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A promotion has an invalid format'
          )
      ),
    bestTimes: yup.object().shape({
      Monday: yup.string().required(),
      Tuesday: yup.string().required(),
      Wednesday: yup.string().required(),
      Thursday: yup.string().required(),
      Friday: yup.string().required(),
      Saturday: yup.string().required(),
      Sunday: yup.string().required()
    }),
    holidays: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'Invalid format'
          )
      ),
    igTags: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A tag has an invalid format'
          )
      ),
    fbTags: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A tag has an invalid format'
          )
      ),
    negativeKeywords: yup.array().of(
      yup.string()
      // .matches(
      //   /^(?:[a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*~)?[a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]+$/,
      //   'A negative keyword has an invalid format'
      // )
    ),
    galleries: yup.object().shape({
      stockPhotos: yup.object().shape({
        enabled: yup.boolean()
      }),
      appLibrary: yup.object().shape({
        enabled: yup.boolean()
      }),
      yelp: yup.object().shape({
        enabled: yup.boolean()
      }),
      gmb: yup.object().shape({
        enabled: yup.boolean()
      }),
      dropbox: yup.object().shape({
        enabled: yup.boolean()
      })
    }),
    logoUrl: yup.string(),
    brand: yup.object().shape({
      primaryColor: yup.string(),
      secondaryColor: yup.string()
    }),
    plan: yup.string()
  });

  const editing = Object.keys(defaultData).length > 0;

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props} className="selected-option">
      {children}
      <div className="selected-logo">
        <Connected />
      </div>
    </components.SingleValue>
  );

  const Option = (props) => (
    <components.Option {...props}>
      <div className="country-option">
        {props.data.label}
        <Disconnected />
      </div>
    </components.Option>
  );

  const Connected = () => (
    <img src="../assets/img/Connected.png" alt="connected" />
  );

  const Disconnected = () => (
    <img src="../assets/img/Disconnected.png" alt="disconnected" />
  );

  const Linked = () => <img src="../assets/img/linked.svg" alt="linked" />;

  const Unlinked = () => (
    <img src="../assets/img/disconnected-photo-library.svg" alt="unlinked" />
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_GROUPS, {
        headers: {
          Authorization: `bearer ${userInfo.accessToken}`
        }
      })
      .then((response) => {
        setLoading(false);
        const groupsData = response.data.groups;
        let groups = groupsData?.map((option, idx) => ({
          value: option.id,
          label: option.groupName
        }));
        setIndustries(groups);
      })
      .catch((error) => {
        setLoading(false);
        console.error('error ' + error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ContentStep = ({ current = false, title, children }) => {
    return (
      <>
        {current && (
          <div>
            <h5
              id="wizard1-h-0"
              tabIndex="-1"
              className={cx('title', {
                current,
                'd-none': !current
              })}>
              {title}
            </h5>
            <section
              id="wizard1-p-0"
              className={cx('body', {
                current,
                'd-none': !current
              })}>
              {children}
            </section>
          </div>
        )}
      </>
    );
  };
  const Step = ({ children, step, current }) => (
    <li
      className={cx({
        first: step === 1,
        done: step < currStep || currStep === steps.length,
        current: currStep === step,
        disabled: !current
      })}>
      {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <span className="number">{step}</span>
        <span style={{ maxWidth: '120px' }} className="title">
          {children}
        </span>
      </a>
    </li>
  );

  const BusinessInformation = ({
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  }) => {
    const [serviceAreas, setServiceAreas] = useState([]);
    const [secondaryServiceAreas, setSecondaryServiceAreas] = useState([]);
    const [plans, setPlans] = useState([]);
    const [gettingPlans, setGettingPlans] = useState(false);

    const getPlans = async () => {
      setGettingPlans(true);

      const equalsCheck = (a, b) => JSON.stringify(a) === JSON.stringify(b);

      const order = [
        'Legacy Plan',
        'Starter Plan',
        'Pro Plan',
        'Plus Plan',
        'Elite Plan'
      ];

      try {
        const response = await axios.get(`${API_GET_PLANS}`);
        const plans = response.data;

        const formattedPlans = plans.map((plan) => ({
          ...plan,
          value: plan.PK,
          label: plan.SK
        }));

        const sortedPlans = formattedPlans.sort(
          (a, b) => order.indexOf(a.label) - order.indexOf(b.label)
        );

        if (!equalsCheck(plans, sortedPlans) || values?.plan === '') {
          setPlans(sortedPlans);
        }
      } catch (e) {
        console.error(e);
        return [];
      } finally {
        setGettingPlans(false);
      }
    };

    useEffect(() => {
      if (plans.length === 0 && !gettingPlans) {
        getPlans();
      }
    }, [plans, values?.plan]);

    return (
      <>
        <div
          style={{
            display: 'flex',
            width: '95%',
            justifyContent: 'space-between',
            marginTop: '35px'
          }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                color: '#8392A5',
                fontWeight: '450'
              }}>
              <p>Business Name</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column'
              }}>
              <input
                id="clientName"
                type="text"
                required
                placeholder="Client Name"
                value={values?.clientName}
                onChange={handleChange}
                className={`form-control ${
                  touched?.clientName && errors?.clientName ? 'is-invalid' : ''
                }`}
                onBlur={handleBlur}
              />
              {touched?.clientName && errors?.clientName && (
                <div className="invalid-feedback d-inline">
                  {errors?.clientName}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Select Time Zone:</p>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('timeZone', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                required
                name="timeZone"
                options={timeZones}
                className="basic-single"
                value={timeZones.find(
                  (option) => option.label === values?.timeZone
                )}
                onChange={(selectedOption) =>
                  setFieldValue('timeZone', selectedOption.label)
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.timeZone && errors?.timeZone
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.timeZone && touched?.timeZone && (
                <div className="invalid-feedback d-inline">
                  {errors?.timeZone}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Select Plan:</p>
              <> </>
              <span
                className={`spinner-border spinner-border-sm mr-1 ${
                  !gettingPlans ? 'hidden' : ''
                }`}
                role="status"
                aria-hidden="true"></span>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('plan', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="plan"
                options={plans}
                value={plans.find((option) => option.value === values?.plan)}
                onChange={async (selectedOption) => {
                  setFieldValue('plan', selectedOption.value);
                }}
                components={{
                  Option,
                  SingleValue
                }}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center'
                  }),
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.plan && errors?.plan
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.plan && touched?.plan && (
                <div className="invalid-feedback d-inline">{errors?.plan}</div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Location City / Primary Service Area</p>
            </div>
            <div className="font" style={{ width: '100%' }}>
              <CreatableSelect
                id="serviceArea"
                name="serviceArea"
                isMulti
                isClearable={false}
                value={
                  serviceAreas?.length > 0
                    ? serviceAreas
                    : values?.serviceArea?.map((servA, index) => {
                        return { value: index, label: servA };
                      })
                }
                noOptionsMessage={() => 'Type a service area'}
                onChange={(selectedOption) => {
                  setServiceAreas(
                    selectedOption ? selectedOption.serviceArea : []
                  );
                  setFieldValue(
                    'serviceArea',
                    selectedOption
                      ? selectedOption?.map((servA) => servA.label)
                      : []
                  );
                }}
                options={serviceAreas}
                placeholder="Type a service area"
                onBlur={() => {
                  setFieldTouched('serviceArea', true);
                }}
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.serviceArea && errors?.serviceArea
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.serviceArea && touched?.serviceArea && (
                <div className="invalid-feedback d-inline">
                  {errors?.serviceArea}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Secondary Service Area</p>
            </div>
            <div className="font" style={{ width: '100%' }}>
              <CreatableSelect
                id="secondaryServiceArea"
                name="secondaryServiceArea"
                isMulti
                isClearable={false}
                value={
                  secondaryServiceAreas?.length > 0
                    ? secondaryServiceAreas
                    : values?.secondaryServiceArea?.map((servA, index) => {
                        return { value: index, label: servA };
                      })
                }
                noOptionsMessage={() => 'Type a secondary service area'}
                onChange={(selectedOption) => {
                  setSecondaryServiceAreas(
                    selectedOption ? selectedOption.secondaryServiceArea : []
                  );
                  setFieldValue(
                    'secondaryServiceArea',
                    selectedOption
                      ? selectedOption?.map((servA) => servA.label)
                      : []
                  );
                }}
                options={secondaryServiceAreas}
                placeholder="Type a secondary service area"
                onBlur={() => {
                  setFieldTouched('secondaryServiceArea', true);
                }}
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.secondaryServiceArea &&
                    errors?.secondaryServiceArea
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.secondaryServiceArea &&
                touched?.secondaryServiceArea && (
                  <div className="invalid-feedback d-inline">
                    {errors?.secondaryServiceArea}
                  </div>
                )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Description</p>
            </div>
            <textarea
              id="description"
              onChange={handleChange}
              value={values?.description}
              onBlur={handleBlur}
              className={`form-control ${
                touched?.description && errors?.description ? 'is-invalid' : ''
              }`}
              rows={3}
              placeholder="Type here..."
              style={{
                width: '100%',
                height: '70px',
                resize: 'none',
                borderRadius: '6px',
                padding: '10px',
                outline: 'none',
                color: '#3B5998',
                fontWeight: '500'
              }}
            />
            {errors?.description && touched?.description && (
              <div className="invalid-feedback d-inline">
                {errors?.description}
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '42.5%'
            }}>
            <BusinessHours
              values={values}
              setFieldValue={(field, value) => {
                setFieldValue(field, value);
              }}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
            />
            <div
              style={{
                display: 'flex',
                width: '100px',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p>Tags and Mergefields</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
              <div className="font" style={{ display: 'flex' }}>
                <p>~Address</p>
              </div>
              <div className="font">
                <input
                  id="mergeFields.~Address"
                  onChange={handleChange}
                  value={values?.mergeFields['~Address']}
                  type="text"
                  style={{
                    width: '100px',
                    height: 'calc(1em + 0.9375rem + 2px)'
                  }}
                  className={`form-control bottom-text ${
                    touched?.mergeFields &&
                    touched?.mergeFields['~Address'] &&
                    errors?.mergeFields &&
                    errors?.mergeFields['~Address']
                      ? 'is-invalid'
                      : ''
                  }`}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {touched?.mergeFields &&
              errors?.mergeFields &&
              touched?.mergeFields['~Address'] &&
              errors?.mergeFields['~Address'] && (
                <div
                  className="invalid-feedback d-inline"
                  style={{ textAlign: 'end' }}>
                  {errors?.mergeFields['~Address']}
                </div>
              )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
              <div className="font" style={{ display: 'flex' }}>
                <p>~Phone Number</p>
              </div>
              <div className="font" style={{ display: 'flex' }}>
                <input
                  id="mergeFields.~Phone"
                  type="text"
                  value={values?.mergeFields['~Phone']}
                  onChange={handleChange}
                  style={{
                    width: '100px',
                    height: 'calc(1em + 0.9375rem + 2px)'
                  }}
                  className={`form-control bottom-text ${
                    touched?.mergeFields &&
                    touched?.mergeFields['~Phone'] &&
                    errors?.mergeFields &&
                    errors?.mergeFields['~Phone']
                      ? 'is-invalid'
                      : ''
                  }`}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {touched?.mergeFields &&
              errors?.mergeFields &&
              touched?.mergeFields['~Phone'] &&
              errors?.mergeFields['~Phone'] && (
                <div
                  className="invalid-feedback d-inline"
                  style={{ textAlign: 'end' }}>
                  {errors?.mergeFields['~Phone']}
                </div>
              )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
              <div className="font" style={{ display: 'flex' }}>
                <p>~Website</p>
              </div>
              <div className="font" style={{ display: 'flex' }}>
                <input
                  id="mergeFields.~Website"
                  onChange={handleChange}
                  value={values?.mergeFields['~Website']}
                  type="text"
                  style={{
                    width: '100px',
                    height: 'calc(1em + 0.9375rem + 2px)'
                  }}
                  className={`form-control bottom-text ${
                    touched?.mergeFields &&
                    touched?.mergeFields['~Website'] &&
                    errors?.mergeFields &&
                    errors?.mergeFields['~Website']
                      ? 'is-invalid'
                      : ''
                  }`}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {touched?.mergeFields &&
              errors?.mergeFields &&
              touched?.mergeFields['~Website'] &&
              errors?.mergeFields['~Website'] && (
                <div
                  className="invalid-feedback d-inline"
                  style={{ textAlign: 'end' }}>
                  {errors?.mergeFields['~Website']}
                </div>
              )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
              <div className="font" style={{ display: 'flex' }}>
                <p>~Email</p>
              </div>
              <div className="font" style={{ display: 'flex' }}>
                <input
                  type="text"
                  id="mergeFields.~Email"
                  onChange={handleChange}
                  value={values?.mergeFields['~Email']}
                  style={{
                    width: '100px',
                    height: 'calc(1em + 0.9375rem + 2px)'
                  }}
                  className={`form-control bottom-text ${
                    touched?.mergeFields &&
                    touched?.mergeFields['~Email'] &&
                    errors?.mergeFields &&
                    errors?.mergeFields['~Email']
                      ? 'is-invalid'
                      : ''
                  }`}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {touched?.mergeFields &&
              errors?.mergeFields &&
              touched?.mergeFields['~Email'] &&
              errors?.mergeFields['~Email'] && (
                <div
                  className="invalid-feedback d-inline"
                  style={{ textAlign: 'end' }}>
                  {errors?.mergeFields['~Email']}
                </div>
              )}
          </div>
        </div>
      </>
    );
  };

  const Content = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  }) => {
    const [promotions, setPromotions] = useState([]);
    const [selectedHolidays, setSelectedHolidays] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [igTags, setIgTags] = useState([]);
    const [fbTags, setFbTags] = useState([]);
    const [contentStrategies, setContentStrategies] = useState([]);
    const [gettingKeywords, setGettingKeywords] = useState(false);
    const [gettingContentStrategy, setGettingContentStrategy] = useState(false);

    const getContentStrategies = async (industry) => {
      setGettingContentStrategy(true);
      const equalsCheck = (a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      };
      try {
        axios
          .get(`${API_GET_CONTENT_STRATEGIES}?industry=${industry}`, {
            headers: { 'X-API-KEY': API_KEY }
          })
          .then((response) => {
            const { contentStrategies: cs } = response.data;
            if (
              !equalsCheck(
                contentStrategies,
                cs.map((strategy) => ({
                  ...strategy,
                  value: strategy.id,
                  label: strategy.strategyName
                }))
              ) ||
              values?.contentStrategy === ''
            ) {
              setContentStrategies(
                cs.map((strategy) => ({
                  ...strategy,
                  value: strategy.id,
                  label: strategy.strategyName
                }))
              );
            }
            setGettingContentStrategy(false);
          });
      } catch (e) {
        console.error(e);
        setGettingContentStrategy(false);
        return [];
      }
      setGettingContentStrategy(false);
    };

    useEffect(() => {
      if (contentStrategies.length === 0 && !gettingContentStrategy) {
        try {
          getContentStrategies(); // TODO: We should pass an argument with industry to get just the content strategies for this industry
        } catch (e) {
          console.error(e);
        }
      }
    }, [contentStrategies, values?.contentStrategy]);

    return (
      <>
        <div
          style={{
            display: 'flex',
            width: '99%',
            justifyContent: 'space-between',
            marginTop: '10px'
          }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row'
              }}
              className="font">
              <p className="p">Industry</p>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('industry', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="industry"
                options={industries}
                className="basic-multi-select"
                value={industries.find(
                  (option) => option?.value === values?.industry
                )}
                onChange={(selectedOption) =>
                  setFieldValue('industry', selectedOption?.value)
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.industry && errors?.industry
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.industry && touched?.industry && (
                <div className="invalid-feedback d-inline">
                  {errors?.industry}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Content Strategy</p>
              <> </>
              <span
                className={`spinner-border spinner-border-sm mr-1 ${
                  !gettingContentStrategy ? 'hidden' : ''
                }`}
                role="status"
                aria-hidden="true"></span>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('contentStrategy', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="contentStrategy"
                options={contentStrategies}
                value={contentStrategies.find(
                  (option) => option.value === values?.contentStrategy
                )}
                onChange={async (selectedOption) => {
                  setFieldValue('contentStrategy', selectedOption.value);
                  if (!editing) {
                    setGettingKeywords(true);
                    let keywords =
                      (await getNewClientsKeywords(selectedOption.value)) || [];
                    setFieldValue('primaryService', keywords);
                    setGettingKeywords(false);
                  }
                }}
                components={{
                  Option,
                  SingleValue
                }}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center'
                  }),
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.contentStrategy && errors?.contentStrategy
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.contentStrategy && touched?.contentStrategy && (
                <div className="invalid-feedback d-inline">
                  {errors?.contentStrategy}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Negative Keywords</p>
            </div>
            <TagsInput
              redTag
              id="negativeKeywords"
              values={values?.negativeKeywords}
              setFieldValue={setFieldValue}
              // extraValues={strategyNegativeKeywords}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Attributes</p>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('attributes', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="attributes"
                className="basic-multi-select"
                options={attributeList}
                value={
                  attributes?.length > 0
                    ? attributes
                    : values?.attributes?.map((attr, index) => {
                        return { value: index, label: attr };
                      })
                }
                onChange={(selectedOption) => {
                  setAttributes(selectedOption ? selectedOption : []);
                  setFieldValue(
                    'attributes',
                    selectedOption
                      ? selectedOption?.map((attr) => attr.label)
                      : []
                  );
                }}
                isMulti
                isClearable={false}
                styles={{
                  // ...customMultiSelectorStyle,
                  // dropdownIndicator: () => ({
                  //   display: 'inline-block',
                  // }),
                  // indicatorSeparator: () => ({
                  //   display: 'inline-block',
                  // }),
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.attributes && errors?.attributes
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.attributes && touched?.attributes && (
                <div className="invalid-feedback d-inline">
                  {errors?.attributes}
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Promotion/Specials</p>
            </div>
            <div className="font" style={{ width: '100%' }}>
              <CreatableSelect
                id="promotions"
                isMulti
                isClearable={false}
                value={
                  promotions?.length > 0
                    ? promotions
                    : values?.promotions?.map((prom, index) => {
                        return { value: index, label: prom };
                      })
                }
                noOptionsMessage={() => 'Type a promotion'}
                onChange={(selectedOption) => {
                  setPromotions(
                    selectedOption ? selectedOption.promotions : []
                  );
                  setFieldValue(
                    'promotions',
                    selectedOption
                      ? selectedOption?.map((promotion) => promotion.label)
                      : []
                  );
                }}
                options={promotions}
                placeholder="Type a promotion"
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.promotions && errors?.promotions
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
                onBlur={() => {
                  setFieldTouched('promotions', true);
                }}
              />
              {errors?.promotions && touched?.promotions && (
                <div className="invalid-feedback d-inline">
                  {errors?.promotions[0]}
                </div>
              )}
            </div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
            <div
              style={{
                display: 'flex',
                width: '100px',
                flexDirection: 'row'
              }}
              className="font">
              <p>Best Time Posting</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100px',
                flexDirection: 'row'
              }}
              className="font">
              <ul className="list-inline list-inline-skills mb-2">
                <div className="table-responsive">
                  <table className="table table-borderless table-sm tx-nowrap">
                    <tbody>
                      {Object?.keys(values?.bestTimes)
                        .sort((a, b) => daysOrder[a] - daysOrder[b])
                        .map((day, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="text-left">{day}</td>
                              <td className="text-right">
                                <input
                                  className="w-100"
                                  type="time"
                                  step="3600"
                                  defaultValue={
                                    values?.bestTimes
                                      ? values?.bestTimes[day] + ':00'
                                      : '12:00'
                                  }
                                  onChange={(event) => {
                                    setFieldValue(
                                      `bestTimes.${day}`,
                                      event.target.value.substring(0, 2)
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </ul>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
              className="font">
              <p className="p">Holidays</p>
            </div>
            <div
              onBlur={() => {
                setFieldTouched('holidays', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="holidays"
                className="basic-multi-select"
                options={holidays}
                value={
                  selectedHolidays?.length > 0
                    ? selectedHolidays
                    : values?.holidays?.map((holiday, index) => {
                        return { value: index, label: holiday };
                      })
                }
                onChange={(selectedOption) => {
                  setSelectedHolidays(selectedOption ? selectedOption : []);
                  setFieldValue(
                    'holidays',
                    selectedOption
                      ? selectedOption?.map((holiday) => holiday.label)
                      : []
                  );
                }}
                isMulti
                isClearable={false}
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.holidays && errors?.holidays
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.holidays && touched?.holidays && (
                <div className="invalid-feedback d-inline">
                  {errors?.holidays}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                width: '100px',
                justifyContent: 'space-between',
                marginTop: '12px'
              }}
              className="font">
              <p className="p"># Instagram Hashtags </p>
            </div>
            <CreatableSelect
              id="igTags"
              isMulti
              isClearable={false}
              value={
                igTags?.length > 0
                  ? igTags
                  : values?.igTags?.map((igt, index) => {
                      return { value: index, label: igt };
                    })
              }
              noOptionsMessage={() => 'Type an instagram tag'}
              onChange={(selectedOption) => {
                setIgTags(selectedOption ? selectedOption : []);
                setFieldValue(
                  'igTags',
                  selectedOption ? selectedOption?.map((igt) => igt.label) : []
                );
              }}
              options={igTags}
              placeholder="Type an instagram tag"
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  ...(touched?.igTags && errors?.igTags
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: 'white',
                  '&::before': {
                    content: "'#'"
                  }
                })
              }}
              onBlur={() => {
                setFieldTouched('igTags', true);
              }}
            />
            {errors?.igTags && touched?.igTags && (
              <div className="invalid-feedback d-inline">
                {errors?.igTags[0]}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                width: '100px',
                justifyContent: 'space-between',
                paddingTop: '10px'
              }}
              className="font">
              <p className="p"># Facebook Hashtags </p>
            </div>

            <CreatableSelect
              id="fbTags"
              isMulti
              isClearable={false}
              value={
                fbTags?.length > 0
                  ? fbTags
                  : values?.fbTags?.map((fbt, index) => {
                      return { value: index, label: fbt };
                    })
              }
              noOptionsMessage={() => 'Type an facebook tag'}
              onChange={(selectedOption) => {
                setFbTags(selectedOption ? selectedOption : []);
                setFieldValue(
                  'fbTags',
                  selectedOption ? selectedOption?.map((fbt) => fbt.label) : []
                );
              }}
              options={fbTags}
              placeholder="Type an facebook tag"
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  ...(touched?.fbTags && errors?.fbTags
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: 'white',
                  '&::before': {
                    content: "'#'"
                  }
                })
              }}
              onBlur={() => {
                setFieldTouched('fbTags', true);
              }}
            />
            {errors?.fbTags && touched?.fbTags && (
              <div className="invalid-feedback d-inline">
                {errors?.fbTags[0]}
              </div>
            )}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Primary Service/Product</p>
              <> </>
              <span
                className={`spinner-border spinner-border-sm mr-1 ${
                  !gettingKeywords ? 'hidden' : ''
                }`}
                role="status"
                aria-hidden="true"></span>
            </div>
            <div className="font" style={{ width: '100%' }}>
              <TagsInput
                blueTag
                id="primaryService"
                values={values?.primaryService}
                setFieldValue={setFieldValue}
                placeholder={'Type a primary service'}
                customStyle={{
                  minHeight: '380px',
                  tagsContainer: { maxHeight: '330px' }
                }}
                // extraValues={strategyNegativeKeywords}
              />
              {errors?.primaryService && touched?.primaryService && (
                <div className="invalid-feedback d-inline">
                  {errors?.primaryService}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '0.7rem'
              }}
              className="font">
              <p className="p">Additional Service/Product</p>
            </div>
            <div className="font" style={{ width: '100%' }}>
              <TagsInput
                blueTag
                id="additionalService"
                values={values?.additionalService}
                setFieldValue={setFieldValue}
                placeholder={'Type an additional Service'}
                customStyle={{
                  minHeight: '380px',
                  tagsContainer: { maxHeight: '330px' }
                }}
              />
              {errors?.additionalService && touched?.additionalService && (
                <div className="invalid-feedback d-inline">
                  {errors?.additionalService[0]}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const ImagesGraphics = ({
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur
  }) => {
    const [primaryColor, setPrimaryColor] = useState({
      hex: values?.brand?.primaryColor || initialValues.brand.primaryColor
    });
    const [secondaryColor, setSecondaryColor] = useState({
      hex: values?.brand?.secondaryColor || initialValues.brand.secondaryColor
    });
    const [primaryIsOpen, setPrimaryIsOpen] = useState(false);
    const [secondaryIsOpen, setSecondaryIsOpen] = useState(false);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState({ length: 0 });
    // =============== S3 BUCKET CONFIG ================
    const config = {
      // dirName: `${props.data.id}` /*optional */,
      bucketName: AWS_BUCKET_CLIENT_LOGOS,
      region: AWS_REGION,
      accessKeyId: AWS_MMS_S3_API_KEY,
      secretAccessKey: AWS_MMS_S3_ACCESS_KEY,
      s3Url: `https://${AWS_BUCKET_CLIENT_LOGOS}.s3.${AWS_REGION}.amazonaws.com`
    };

    // =============== UPLOAD IMAGES==============
    const renderImages = (files) => {
      let i = 0;

      let itemsList = [];
      while (i < files.length) {
        itemsList[i] = files[i];

        i++;
      }
      itemsList = itemsList.map((item, index) => {
        function returnFileSize(number) {
          if (number < 1024) {
            return number + 'bytes';
          } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
          } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
          }
        }
        function handleName(name) {
          if (name.length > 35) {
            return name.slice(0, 35) + ' ...';
          } else return name;
        }
        return (
          <div
            key={index}
            className="card"
            style={{
              width: '48%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 5,
                marginTop: 5,
                overflow: 'hidden'
              }}>
              <b>FileName: </b>
              <u>{handleName(item.name)}</u>
              <b>Size: </b>
              {returnFileSize(item.size)}
            </div>
            <div>
              <img
                alt=""
                src={URL.createObjectURL(item)}
                style={{ width: 100, height: 100, objectFit: 'cover' }}
              />
            </div>
          </div>
        );
      });

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexFlow: 'row wrap',
            alignContent: 'flex-end',
            justifyContent: 'space-between'
          }}>
          {itemsList}
        </div>
      );
    };
    const handleUpload = async (file) => {
      let newFileName = `${uuid()}-${uuid()}`;
      const ReactS3Client = new S3(config);
      try {
        const { location } = await ReactS3Client.uploadFile(file, newFileName);
        return location.toString();
      } catch (e) {
        console.error(e);
        return '';
      }
    };
    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            paddingTop: '2%',
            width: '50%'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}>
            <div
              style={{
                padding: '10px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '30px 0'
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100px'
                  }}>
                  <h6>Stock Photos</h6>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <input
                    id="galleries.stockPhotos.enabled"
                    checked={Boolean(values?.galleries?.stockPhotos?.enabled)}
                    onChange={(ev) => {
                      setFieldValue(
                        'galleries.stockPhotos.enabled',
                        ev.target.checked
                      );
                    }}
                    type="checkbox"
                    style={{
                      opacity: 1,
                      cursor: 'pointer',
                      height: '14px',
                      width: '14px',
                      backgroundColor: '#F3F4F7',
                      color: '#F3F4F7'
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%'
                  }}></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '5px'
                  }}>
                  {values?.galleries?.stockPhotos?.enabled ? (
                    <Linked />
                  ) : (
                    <Unlinked />
                  )}
                </div>
              </div>
              {editing ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '30px 0'
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100px'
                      }}>
                      <h6>App Library</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <input
                        id="galleries.appLibrary.enabled"
                        checked={Boolean(
                          values?.galleries?.appLibrary?.enabled
                        )}
                        onChange={(ev) => {
                          setFieldValue(
                            'galleries.appLibrary.enabled',
                            ev.target.checked
                          );
                        }}
                        type="checkbox"
                        style={{
                          opacity: 1,
                          cursor: 'pointer',
                          height: '14px',
                          width: '14px',
                          backgroundColor: '#F3F4F7',
                          color: '#F3F4F7'
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '60%'
                      }}></div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '12px'
                      }}>
                      {values?.galleries?.appLibrary?.enabled ? (
                        <Linked />
                      ) : (
                        <Unlinked />
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            paddingTop: '2%',
            paddingLeft: '10%'
          }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column',
              paddingTop: '8%'
            }}
            className="font">
            <div>
              <p>Logo Upload</p>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <input
                style={{
                  visibility: 'hidden',
                  position: 'absolute'
                }}
                id="image_uploads"
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
                multiple={false}
                type="file"
                disabled={uploadingImages}
                accept="image/jpeg, image/png"
              />
            </div>
            <label
              style={{
                width: '100px',
                height: '80px',
                resize: 'none',
                borderRadius: '6px',
                padding: '10px',
                outline: 'none',
                border: 'none',
                color: '#3B5998',
                fontWeight: '500',
                backgroundColor: '#F3F4F7',
                backgroundImage: `url(${values?.logoUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
              className={`form-control btn`}
              htmlFor="image_uploads"></label>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column',
              paddingTop: '20%'
            }}
            className="font">
            <div>
              <p>Brand Colors</p>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '255px',
                marginBottom: '10px'
              }}>
              <div
                className="square"
                onClick={() => {
                  setPrimaryIsOpen(!primaryIsOpen);
                  if (secondaryIsOpen) setSecondaryIsOpen(false);
                }}
                style={{
                  backgroundColor: primaryColor.hex,
                  cursor: 'pointer'
                }}></div>
              <span style={{ width: '65px' }}>
                {primaryColor?.hex?.toUpperCase()}
              </span>
              <div
                className="square"
                onClick={() => {
                  setSecondaryIsOpen(!secondaryIsOpen);
                  if (primaryIsOpen) setPrimaryIsOpen(false);
                }}
                style={{
                  backgroundColor: secondaryColor.hex,
                  cursor: 'pointer'
                }}></div>
              <span style={{ width: '65px' }}>
                {secondaryColor?.hex?.toUpperCase()}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '284px'
              }}>
              <div
                style={{
                  width: '142px'
                }}>
                {primaryIsOpen && (
                  <BlockPicker
                    color={primaryColor}
                    onChangeComplete={(val) => {
                      setPrimaryColor(val);
                      setFieldValue(
                        'brand.primaryColor',
                        val.hex.toUpperCase()
                      );
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  width: '142px'
                }}>
                {secondaryIsOpen && (
                  <BlockPicker
                    color={secondaryColor}
                    onChangeComplete={(val) => {
                      setSecondaryColor(val);
                      setFieldValue(
                        'brand.secondaryColor',
                        val.hex.toUpperCase()
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div></div>
        </div>
        {files.length > 0 ? (
          <div
            className="form-group"
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            <React.Fragment>
              <div className="modal-backdrop in"></div>
              <div
                className="modal fade in show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: 'block' }}>
                <div
                  className="modal-dialog modal-dialog-centered modal-lg tx-2"
                  role="document">
                  <div className="modal-content tx-12">
                    <div className="modal-header">
                      <h5 className="modal-title" id="addNewGroupLabel">
                        Upload Images ({files.length})
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setFiles({ length: 0 })}
                        disabled={uploadingImages || loading}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="card"
                        style={{
                          maxHeight: '500px',
                          minHeight: '325px',
                          overflow: 'scroll'
                        }}>
                        <div className="card-body">
                          <div>{renderImages(files)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        disabled={uploadingImages}
                        className="btn btn-danger tx-13"
                        data-dismiss="modal"
                        onClick={() => setFiles({ length: 0 })}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={uploadingImages || files.length === 0}
                        className="btn btn-primary"
                        onClick={async () => {
                          setUploadingImages(true);
                          config.name = files[0].name;
                          setProgress(0);
                          let newArr = files;
                          for (let i = 0; i < newArr.length; i++) {
                            const location = await handleUpload(newArr[i]);
                            if (i !== 0) {
                              let pr = (i / files.length) * 100;
                              setProgress(Math.round(pr));
                            } else {
                              setProgress(0);
                            }
                            setFieldValue('logoUrl', location);
                          }

                          setTimeout(async () => {
                            setUploadingImages(false);
                            setFiles({ length: 0 });
                            setProgress(0);
                          }, 500);
                        }}>
                        <span
                          className={`spinner-border spinner-border-sm mr-1 ${
                            !uploadingImages ? 'hidden' : ''
                          }`}
                          role="status"
                          aria-hidden="true"></span>
                        {uploadingImages
                          ? 'Uploading...' +
                            `${progress !== 0 ? progress : '0'}%`
                          : 'Upload'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        ) : null}
      </div>
    );
  };

  const RenderContentSteps = ({ ...props }) => {
    return steps.map((step, index) => {
      return (
        <ContentStep
          key={index}
          current={currStep === index + 1}
          title={step.title}>
          {step.content({ ...props })}
        </ContentStep>
      );
    });
  };

  const getNewClientsKeywords = async (id) => {
    try {
      const response = await axios.post(GET_PRIMARY_SERVICES, { id });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const modifyProps = async (props) => {
    const { values } = props;

    let newKeywords = [];
    if (!values?.contentStrategy) {
      const id =
        'ce41946d74ddad9950d063a07b161237eb3ad41317378b1db38d6fd168d1d50f';
      newKeywords = await getNewClientsKeywords(id);
    } else {
      newKeywords = await getNewClientsKeywords(values?.contentStrategy);
    }

    const modifiedProps = {
      ...props,
      values: {
        ...values,
        primaryService: [...values?.primaryService, ...newKeywords]
      }
    };
    return modifiedProps;
  };

  const steps = [
    {
      title: 'Business Information',
      content: ({ ...props }) => <BusinessInformation {...props} />
    },
    {
      title: 'Content',
      content: ({ ...props }) => <Content {...props} />
    },
    {
      title: 'Images/Graphics',
      content: ({ ...props }) => <ImagesGraphics {...props} />
    }
  ];
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const reset = (resetForm) => {
    resetForm();
  };

  const handlePut = async (data = {}) => {
    const newClientSave = async (data) => {
      const newClientUrl = API_NEWCLIENT;
      setLoading(true);
      let newClientName = data.clientName.replace(/\s+/g, ' ');
      newClientName = newClientName.replace(/^\s+|\s+$/g, '');
      newClientName = newClientName.replace(/’/g, "'");

      const clientData = {
        ...data,
        id: defaultData?.id,
        clientName: newClientName
      };

      await axios({
        method: 'post',
        url: newClientUrl,
        data: clientData
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              type: 'success',
              title: 'Success!',
              text: `The client was ${
                clientData.id ? 'updated' : 'created'
              } successfully.`
            }).then(async () => {
              setCurrStep(1);
              setLoading(false);
              handleClose();
              someFn(clientData);
              if (clientData?.id) {
                const clData = await axios.get(
                  `${API_CLIENTS_NAME}${clientData.clientName}`,
                  {
                    headers: {
                      'X-API-KEY': API_KEY,
                      Authorization: `bearer ${userInfo.accessToken}`
                    }
                  }
                );
                let newUrl = clData?.data?.clientName
                  .replace(/\s+/g, '%20')
                  .replace(/\//g, '%2F')
                  .replace(/'/g, '%1D')
                  .replace(/’/g, '%1E');
                history.push(`/client/${newUrl}`);

                if (clData.data) {
                  dispatch(setSelectedClient(clData.data));
                }
                return;
              }
              const clData = await axios.get(
                `${API_CLIENTS_NAME}${clientData.clientName}`,
                {
                  headers: {
                    'X-API-KEY': API_KEY,
                    Authorization: `bearer ${userInfo.accessToken}`
                  }
                }
              );

              let newUrl = clientData.clientName
                .replace(/\s+/g, '%20')
                .replace(/\//g, '%2F')
                .replace(/'/g, '%1D')
                .replace(/’/g, '%1E');
              history.push(`/calendar/${newUrl}`);

              if (clData.data) {
                dispatch(setSelectedClient(clData.data));
                dispatch(generateContent());
              }
            });
          }
        })
        .catch(function (xhr, ajaxOptions, thrownError) {
          Swal.fire({
            type: 'error',
            title: 'Error!',
            text: 'The client was not created successfully due an error.'
          }).then(() => {
            // TODO: Handle error
          });
        });
    };

    await newClientSave(data);
  };
  const someFn = (listInfo) => {
    callbackFromParent(listInfo);
  };
  return (
    <Modal
      id="modal-dialog-onboarding"
      backdrop="static"
      keyboard={false}
      show={showModal}
      onHide={handleClose}>
      <Formik
        onSubmit={(data) => {
          handlePut(data);
        }}
        validateOnBlur={true}
        initialValues={
          editing
            ? {
                ...defaultData,
                ...(!defaultData.businessHours?.monday
                  ? { businessHours: initialValues.businessHours }
                  : {}),
                ...(!defaultData?.brand?.primaryColor
                  ? { brand: initialValues.brand }
                  : {})
              }
            : null || initialValues
        }
        validationSchema={validationSchema}>
        {(props) => {
          const { handleSubmit, handleReset, setFieldTouched, values } = props;
          const isLastStep = currStep >= steps.length;
          const buttonText = isLastStep
            ? editing
              ? 'Update data'
              : 'Start Posting'
            : 'Next';

          const buttonLabel = loading ? '' : buttonText;
          return (
            <Form
              autoComplete="off"
              onKeyDown={onKeyDown}
              className="needs-validation"
              noValidate>
              <div id="wizard1" className="wizard vertical">
                <Modal.Header>
                  <h5 className="modal-title" id="addNewClientLabel">
                    {editing ? 'Edit Client Data' : 'New Client Onboarding'}
                  </h5>
                  <button
                    onClick={() => {
                      reset.bind(null, handleReset());
                      setLoading(false);
                      handleClose();
                      setCurrStep(1);
                    }}
                    className="close"
                    type="button">
                    <span aria-hidden="true">×</span>
                  </button>
                </Modal.Header>
                <div className="steps clearfix">
                  <ul>
                    {steps.map((step, index) => {
                      return (
                        <Step
                          key={index}
                          step={index + 1}
                          current={currStep === index + 1}>
                          {step.title}
                        </Step>
                      );
                    })}
                  </ul>
                </div>
                <div
                  className="content clearfix"
                  style={{ maxHeight: '630px' }}>
                  <RenderContentSteps {...props} />
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className="disabled"
                      style={{
                        visibility: currStep !== 1 ? 'visible' : 'hidden'
                      }}>
                      <button
                        type="button"
                        className="btn"
                        disabled={loading}
                        onClick={() =>
                          setCurrStep(currStep !== 1 ? currStep - 1 : 1)
                        }>
                        Previous
                      </button>
                    </li>
                    <li>
                      <button
                        type={
                          currStep === steps.length + 1 ? 'button' : 'button'
                        }
                        className={cx('btn', [
                          { laststep: currStep === steps.length }
                        ])}
                        disabled={loading}
                        onClick={() => {
                          const fieldsToValidate = [
                            ...(currStep === 1
                              ? [
                                  'clientName',
                                  'timeZone',
                                  'serviceArea',
                                  'secondaryServiceArea',
                                  'description',
                                  'businessHours.monday',
                                  'businessHours.tuesday',
                                  'businessHours.wednesday',
                                  'businessHours.thursday',
                                  'businessHours.friday',
                                  'businessHours.saturday',
                                  'businessHours.sunday',
                                  'mergeFields.~Address',
                                  'mergeFields.~Phone',
                                  'mergeFields.~Website',
                                  'mergeFields.~Email',
                                  'mergeFields.~City'
                                ]
                              : []),
                            ...(currStep === 2
                              ? [
                                  'industry',
                                  'contentStrategy',
                                  'primaryService',
                                  'additionalService',
                                  'attributes',
                                  'promotions',
                                  'bestTimes.Monday',
                                  'bestTimes.Tuesday',
                                  'bestTimes.Wednesday',
                                  'bestTimes.Thursday',
                                  'bestTimes.Friday',
                                  'bestTimes.Saturday',
                                  'bestTimes.Sunday',
                                  'holidays',
                                  'igTags',
                                  'fbTags',
                                  'negativeKeywords',
                                  'plan'
                                ]
                              : []),
                            ...(currStep === 3 ? [] : [])
                          ];

                          const subFormErrors = {};

                          fieldsToValidate.forEach((field) => {
                            try {
                              validationSchema.validateSyncAt(field, values);
                            } catch (error) {
                              subFormErrors[field] = error.message;
                              setFieldTouched(field, true);
                            }
                          });

                          if (Object.keys(subFormErrors).length === 0) {
                            if (currStep === steps.length) {
                              handleSubmit();
                            } else {
                              setCurrStep((prevStep) => prevStep + 1);
                            }
                          } else {
                            // warning to the user that some fields have errors
                          }
                        }}>
                        {buttonLabel}
                        {loading && (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          />
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NewClientOnboarding;
